import React, { useEffect, useState } from "react";
import SpinnerComp from "./components/spinner/SpinnerComp";
import DefaultContainerWrapper from "./pages/Accounts/DefaultContainerWrapper";
import { makeRequest } from "./utilities/axio.helper";

function withLoading(WrappedComponent: any, url: string) {
  function LoadingComponent(props: any) {
    const [data, setData] = useState([]);
    const [isFetching, setIsFetching] = useState(false);

    const fetchData = async () => {
      setIsFetching(true);
      try {
        const data = await makeRequest("post", url);
        setData(data);
      } catch (e) {
        toastr.error((e as any).message);
      } finally {
        setIsFetching(false);
      }
    };

    useEffect(() => {
      fetchData();
    }, []);

    return (
      <>
        {isFetching ? (
          <DefaultContainerWrapper loading={isFetching}>
            <SpinnerComp animation="border" />
          </DefaultContainerWrapper>
        ) : (
          <WrappedComponent {...props} roles={data} />
        )}
      </>
    );
  }
  return LoadingComponent;
}

export default withLoading;

import React, { FunctionComponent, useState, useEffect } from "react";
import { Account } from "src/models/app/Account";
import { Table, Button, Card } from "react-bootstrap";
import { makeRequest } from "src/utilities/axio.helper";
import urls from "src/utilities/urls";
import { Exclusion } from "src/models/app/Exclusion";
import { RequestGetExclusionDto } from "src/models/dto/exclusion.dto";
import { AccountExclusionItem } from "./AccountExclusionItem";
import { CreateAccountExclusionModal } from "./CreateAccountExclusionModal";

export interface IAccountExclusionsProps {
  account: Account;
}

export const AccountExclusions: FunctionComponent<IAccountExclusionsProps> = (
  props: IAccountExclusionsProps,
) => {
  const [exclusions, setExclusions] = useState<Exclusion[]>();
  const showCreateExclusionModal = (): void => setShowCreateModal(true);
  const hideCreateExclusionModal = (): void => setShowCreateModal(false);
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
  const [refreshExclusions, setRefreshExclusions] = useState<boolean>(false);

  const getCreateExclusionModal = (): React.ReactChild => {
    if (showCreateModal && props.account) {
      return (
        <CreateAccountExclusionModal
          account={props.account}
          removeModal={hideCreateExclusionModal}
          refreshData={(): void => setRefreshExclusions(!refreshExclusions)}
        />
      );
    }

    return <></>;
  };

  useEffect(() => {
    async function loadExclusions() {
      const response = await makeRequest<Exclusion[], RequestGetExclusionDto>(
        "post",
        urls.EXCLUSIONS_LIST,
        {
          accountId: props.account.id,
        },
      );
      setExclusions(response);
    }

    loadExclusions();
  }, [props.account.id, refreshExclusions]);

  return (
    <>
      <Card>
        <Card.Header>
          Account Exclusions
          <Button
            className="float-right"
            variant="primary"
            onClick={showCreateExclusionModal}
          >
            Create Exclusion
          </Button>
        </Card.Header>
        <Card.Body>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Type</th>
                <th>Active</th>
                <th>Start Date</th>
                <th>End Date</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {exclusions &&
                exclusions.map((exclusion, index) => (
                  <AccountExclusionItem
                    key={index}
                    exclusionDto={exclusion}
                    accountId={props.account.id}
                  />
                ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
      {getCreateExclusionModal()}
    </>
  );
};

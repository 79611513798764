import React, { FunctionComponent, useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { Theme } from "src/models/app/Theme";
import urls from "src/utilities/urls";
import { makeRequest } from "src/utilities/axio.helper";
import { RequestUpdateThemeDto } from "src/models/dto/theme.dto";

export interface IAccountLimitCreateModal {
  removeModal: () => void;
  onCreated: (theme: Theme) => void;
}

export const ThemeCreateModal: FunctionComponent<IAccountLimitCreateModal> = (
  props: IAccountLimitCreateModal,
) => {
  const [theme, setTheme] = useState<Theme>(new Theme());

  const onHide = (): void => props.removeModal();

  const onSave = async (): Promise<void> => {
    try {
      // aCreate Limit
      await makeRequest<Theme, RequestUpdateThemeDto>("post", urls.THEMES, {
        ...theme,
      });

      props.onCreated(theme);
      toastr.success("Created Theme!");

      onHide();
    } catch (err) {
      toastr.error(err.message);
    }
  };

  const renderModal = (): React.ReactChild => {
    return (
      <Modal show={true} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Create Theme</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="name">
              <Form.Label>Theme Name</Form.Label>
              <Form.Control
                type="text"
                value={theme.name}
                onChange={(e): void => {
                  setTheme({ ...theme, name: e.target.value });
                }}
              />
            </Form.Group>
            <Form.Group controlId="code">
              <Form.Label>Theme Code</Form.Label>
              <Form.Control
                type="text"
                value={theme.code}
                onChange={(e): void => {
                  setTheme({ ...theme, code: e.target.value });
                }}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={onSave}>
            Create
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  return <>{renderModal()}</>;
};

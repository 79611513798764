/**
 //////////////////////////////////////////////////////////////////////
 THIS FILE WAS GENEREATED
 Run 'npm run export-models' on backend to sync models
 ((DO NOT MODIFIY!!!))
 //////////////////////////////////////////////////////////////////////
 */

// Models
import { Language } from "./Languages";
import { Country } from "./Country";
import { Transaction } from "./Transaction";
import { LoginHistory } from "./LoginHistory";
import { Wager } from "./Wager";
import { FavouriteGame } from "./FavouriteGame";
import { AccountSnapShot } from "./AccountSnapShot";
import { DepositLimit } from "./DepositLimit";
import { Exclusion } from "./Exclusion";
import { Wallet, WalletWithCurrency } from "./Wallet";
import { AccountStatusLog } from "./AccountStatusLog";
import { Comment } from "./Comment";
import { AccountTag } from "./AccountTag";
import { AccountDocument } from "./AccountDocument";
import { PaymentAccount } from "./PaymentAccount";

export interface AccountWithDetails extends Account {
  language: Language;
  country: Country;
  wallet: WalletWithCurrency;
}

export class Account {
  id: number;

  name: string;

  email: string;

  dateOfBirth: Date;

  houseNumber: string;

  street: string;

  city: string;

  zipcode: string;

  country?: Country;

  state: string;

  language?: Language;

  cprNumber: string;

  pid: string;

  emailMarketing: boolean;

  smsMarketing: boolean;

  isTest: number;

  active: boolean;

  phoneNumber: string;

  createdAt: Date;

  updatedAt: Date;

  deletedAt: Date;

  //  End
  statusLogs: AccountStatusLog[];

  comments: Comment[];

  wallets?: Wallet[];

  transactions?: Transaction[];

  depositLimits: DepositLimit[];

  exclusions?: Exclusion[];

  wager?: Wager;

  accountSnapShot?: AccountSnapShot;

  loginHistory?: LoginHistory[];

  favouriteGames?: FavouriteGame[];

  accountTags: AccountTag[];

  emailVerified: boolean;

  phoneVerified: boolean;

  accountDocuments?: AccountDocument[];

  paymentAccounts?: PaymentAccount[];
}

import React, {
  FunctionComponent,
  useState,
  Dispatch,
  SetStateAction,
} from "react";
import { Game } from "../../../models/app/Game";
import { Button, CloseButton, Form } from "react-bootstrap";
import urls from "src/utilities/urls";
import { RequestUpdateGameThemeDto } from "src/models/dto/game.theme.dto";
import { makeRequest } from "src/utilities/axio.helper";
import { GameTheme } from "src/models/app/GameTheme";
import { RequestGetGamesDto, UpdateGameDto } from "src/models/dto/game.dto";
import { Theme } from "src/models/app/Theme";

const NEW_GAME_THEME_ID: number = 3;
const MAX_NEW_GAMES_COUNT: number = 24;

interface IThemeGamesRowComponent {
  game: Game;
  theme?: Theme;
  setGames: Dispatch<SetStateAction<Game[]>>;
  index: number;
  topWeight?: number;
}

const ThemeGamesRow: FunctionComponent<IThemeGamesRowComponent> = (
  props: IThemeGamesRowComponent,
) => {
  const [gameTheme, setGameTheme] = useState<GameTheme>(
    props.game.gameThemes[0],
  );

  const [initialWeight, setInitialWeight] = useState<any>(
    props.game.gameThemes[0]?.weight,
  );

  const updateGameTheme = async (forceWeightUpdate?: number): Promise<void> => {
    try {
      await makeRequest<GameTheme, RequestUpdateGameThemeDto>(
        "post",
        urls.GAME_THEMES,
        {
          ...gameTheme,
          weight: forceWeightUpdate ? forceWeightUpdate : gameTheme.weight,
        },
      );
      setInitialWeight(gameTheme.weight);
      props.setGames([]);
      toastr.success("Updated Weight!");
    } catch (err) {
      toastr.error(err.message);
    }
  };

  const changeWeight = () => {
    return (
      <Form.Control
        type="number"
        value={gameTheme.weight}
        onChange={(evt: React.ChangeEvent<HTMLInputElement>): void => {
          setGameTheme({
            ...gameTheme,
            weight: Number(evt.target.value),
          });
        }}
        onBlur={(): void => {
          if (initialWeight !== gameTheme.weight) updateGameTheme();
        }}
        onKeyDown={(evt: React.KeyboardEvent<HTMLDivElement>) => {
          if (evt.key === "Enter") {
            if (initialWeight !== gameTheme.weight) updateGameTheme();
          }
        }}
      />
    );
  };

  const removeFromTheme = async () => {
    try {
      const { games } = await makeRequest<
        { games: Game[]; totalGames: number },
        RequestGetGamesDto
      >("post", `${urls.GAMES_LIST}`, {
        where: {
          id: [props.game.id],
        },
      });

      const currentThemeIds: number[] = games[0].gameThemes.map(
        (theme) => theme.themeId,
      );

      await makeRequest<Game, UpdateGameDto>("post", `${urls.GAMES_UPDATE}`, {
        ...games[0],
        gameThemesIds: currentThemeIds.filter((themeId) => {
          if (props.theme) {
            return themeId !== props.theme.id;
          }
          return true;
        }),
      });

      // Updates the game list ??
      props.setGames([]);

      toastr.success(`[${games[0].name}] removed from [${props.theme?.name}]!`);
    } catch (err) {
      toastr.error(err.message);
    }
  };

  const additionnalProperties: any = {};
  if (
    props.theme?.id === NEW_GAME_THEME_ID &&
    props.index > MAX_NEW_GAMES_COUNT - 1
  ) {
    additionnalProperties.style = { background: "#FFE8E3" };
  }

  return (
    <tr key={props.game.id} {...additionnalProperties}>
      <td>
        {" "}
        <a href={`/games/edit/${props.game.id}`}>{props.game.id}</a>
      </td>
      <td>{props.game.name}</td>
      <td>{changeWeight()}</td>
      <td align="center">
        <Button
          disabled={props.index === 0}
          hidden={props.index === 0}
          onClick={async () => {
            if (props.topWeight) {
              await updateGameTheme(props.topWeight + 5);
            }
          }}
        >
          {"TOP"}
        </Button>
      </td>
      <td align="center">
        <CloseButton style={{ float: "none" }} onClick={removeFromTheme} />
      </td>
    </tr>
  );
};

export default ThemeGamesRow;

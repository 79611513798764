import React, { FunctionComponent } from "react";
import { Table } from "react-bootstrap";
import TableRow from "./TableRow";
import { AccountDocument } from "../../../models/app/AccountDocument";
import { PaymentAccount } from "src/models/app/PaymentAccount";

interface IDocumentsTable {
  documents: AccountDocument[];
  requireActionButtons: boolean;
  refreshAccount: () => void;
  expiryDate: boolean;
  paymentOption: boolean;
  documentSide: boolean;
  paymentAccounts: PaymentAccount[];
}

const DocumentsTable: FunctionComponent<IDocumentsTable> = (
  props: IDocumentsTable,
) => {
  return (
    <Table hover className="bg-white">
      <thead>
        <tr className="text-center">
          <th>#</th>
          <th>Name</th>
          <th>Type</th>
          <th>State</th>
          <th>Uploaded At</th>
          <th>Updated At</th>
          <th>Deleted At</th>

          {props.documentSide && (
            <>
              <th>Document Side</th>
            </>
          )}
          {props.expiryDate && (
            <>
              <th>Expiry date</th>
              <th></th>
            </>
          )}
          {props.requireActionButtons && (
            <>
              <th></th>
              <th></th>
            </>
          )}
        </tr>
      </thead>
      <tbody>
        {props.documents.map((document: AccountDocument, index) => {
          return document ? (
            <TableRow
              key={document.id}
              document={document}
              index={index}
              requireActionButtons={props.requireActionButtons}
              refreshAccount={props.refreshAccount}
              expiryDate={props.expiryDate}
              paymentOption={props.paymentOption}
              documentSide={props.documentSide}
              paymentAccounts={props.paymentAccounts}
            />
          ) : (
            <></>
          );
        })}
      </tbody>
    </Table>
  );
};

export default DocumentsTable;

/**
    //////////////////////////////////////////////////////////////////////
    THIS FILE WAS GENEREATED
    Run 'npm run export-models' on backend to sync models
    ((DO NOT MODIFIY!!!))
    //////////////////////////////////////////////////////////////////////
    */

/* -------------------------------- Models -------------------------------- */
import { Game } from "./Game";

import { BonusType } from "./BonusType";

export class BonusGame {
  gameId: number;

  bonusTypeId: number;

  wageringPercentage: number;

  game: Game;

  bonusType: BonusType;
}

import { AccountDocument } from "./AccountDocument";

export enum AccountDocumentStateCodes {
  UNVERIFIED = "unverified",
  REJECTED = "rejected",
  VERIFIED = "verified",
  EXPIRED = "expired",
}

export class AccountDocumentState {
  id: number;

  name: string;

  code: AccountDocumentStateCodes;

  description: string;

  createdAt: Date;

  updatedAt: Date;

  deletedAt: Date;

  accountDocuments?: AccountDocument[];
}

import React, { FunctionComponent } from "react";
import { Form, Card } from "react-bootstrap";
import { makeRequest } from "../../utilities/axio.helper";
import urls from "../../utilities/urls";
import { Account } from "src/models/app/Account";

interface IProps {
  account: Account;
  refreshAccount?: () => void;
}

const MAX_FILE_SIZE = 5 * (1024 * 1024);

export const FileUpload: FunctionComponent<IProps> = ({
  account,
  refreshAccount,
}) => {
  return (
    <Card.Body className="text-center pt-2">
      <Form.File custom>
        <Form.File.Label>Click to upload document</Form.File.Label>
        <Form.File.Input
          onChange={async ({
            target: { files },
          }: React.ChangeEvent<HTMLInputElement>) => {
            const file = files?.[0];
            if (file) {
              try {
                const formData = new FormData();
                formData.append("file", file);
                formData.append("accountId", account.id.toString());
                if (file.size > MAX_FILE_SIZE) {
                  toastr.error(
                    `Maximum file size is ${MAX_FILE_SIZE / (1024 * 1024)} MB`,
                    "FILE SIZE LIMIT EXCEEDED ",
                  );
                  return;
                }
                await makeRequest<any, FormData>(
                  "post",
                  urls.DOCUMENTS_UPLOAD,
                  formData,
                );

                // Refresh list
                refreshAccount && refreshAccount();

                toastr.success(`${file.name} uploaded!`);
              } catch (e) {
                const error: { code: string; message: string } = e as any;
                toastr.error(error.code, error.message);
              } finally {
                refreshAccount?.();
              }
            }
          }}
        />
      </Form.File>
    </Card.Body>
  );
};

/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FunctionComponent } from "react";
import { Form } from "react-bootstrap";

export interface IDocumentSide {
    isPOF_FrontChecked: boolean;
    setPOF_FrontChecked: any;
    isPOF_BackChecked: boolean;
    setPOF_BackChecked: any;
    disabledCheckbox?: boolean;
  }
const PaymentOption: FunctionComponent<IDocumentSide> = (
  props: IDocumentSide,
) => {
    return (
        <>
        <Form.Label>Please edit payment details</Form.Label>
          <Form.Check
          label="Front"
          type="radio"
          checked={props.isPOF_FrontChecked}
          disabled={props.disabledCheckbox}
          onChange={(
            evt: React.ChangeEvent<HTMLInputElement>,
          ): void => {
            if (evt.target) {
              if (evt.target.checked) {
                props.setPOF_FrontChecked(true);
                props.setPOF_BackChecked(false);
              } else {
                props.setPOF_FrontChecked(false);
                props.setPOF_BackChecked(true);
              }
            }
          }}
          />
          <Form.Check
          label="Back"
          type="radio"
          checked={props.isPOF_BackChecked}
          disabled={props.disabledCheckbox}
          onChange={(
            evt: React.ChangeEvent<HTMLInputElement>,
          ): void => {
            if (evt.target) {
              if (evt.target.checked) {
                props.setPOF_FrontChecked(false);
                props.setPOF_BackChecked(true);
              } else {
                props.setPOF_FrontChecked(true);
                props.setPOF_BackChecked(false);
              }
            }
          }}
          />
        </>
    )
};

export default PaymentOption;

// Models

export enum Tags {
  TEST = 1,
  TEMPORARY_EXCLUSION = 2,
  PERMANENT_EXCLUSION = 3,
  ROFUS_PERMANENT = 4,
  ROFUS_TEMPORARY = 5,
  ROFUS_MARKETING = 6,
  VERIFIED = 7,
  UNVERIFIED = 8,
  AML_DEPOSITS = 9,
  BLOCKED_WITHDRAWALS = 10,
  CLOSED = 11,
  PEP = 12,
  SANCTIONED = 13,
  NO_RECORD = 14,
  HIGH_RISK = 15,
  HELLO_SODA_CHECK_ERROR = 16,
  RISK_MONITORING_ERROR = 17,
  MONITORED = 18,
  MEDIUM_RISK = 19,
  SOF_REQUIRED = 20, // Source of Funds Required
  SOF_VERIFIED = 21, // Source of Funds Approved
  POI_REQUIRED = 22, // Proof of Identity Required
  POI_VERIFIED = 23, // Proof of Idenitry Approved
  POA_REQUIRED = 24, // Proof of Address Required
  POA_VERIFIED = 25, // Proof of Address Approved
  POF_REQUIRED = 26, // Proof of Funds Required
  POF_VERIFIED = 27, // Proof of Funds Approved
  BLOCKED_DEPOSIT = 28,
}

export const KYC_TAGS = [
  Tags.SOF_REQUIRED,
  Tags.SOF_VERIFIED,
  Tags.POI_REQUIRED,
  Tags.POI_VERIFIED,
  Tags.POA_REQUIRED,
  Tags.POA_VERIFIED,
  Tags.POF_REQUIRED,
  Tags.POF_VERIFIED,
];

const TagsColors: { [key: string]: { color: string; tags: Tags[] } } = {
  BLUE: {
    color: "primary",
    tags: [],
  },
  GREEN: {
    color: "success",
    tags: [
      Tags.NO_RECORD,
      Tags.MONITORED,
      Tags.SOF_VERIFIED,
      Tags.POA_VERIFIED,
      Tags.POI_VERIFIED,
      Tags.POF_VERIFIED,
      Tags.VERIFIED,
    ],
  },
  RED: {
    color: "danger",
    tags: [
      Tags.HIGH_RISK,
      Tags.HELLO_SODA_CHECK_ERROR,
      Tags.RISK_MONITORING_ERROR,
      Tags.CLOSED,
    ],
  },
  YELLOW: {
    color: "warning",
    tags: [
      Tags.TEMPORARY_EXCLUSION,
      Tags.ROFUS_TEMPORARY,
      Tags.ROFUS_MARKETING,
      Tags.UNVERIFIED,
      Tags.AML_DEPOSITS,
      Tags.BLOCKED_WITHDRAWALS,
      Tags.BLOCKED_DEPOSIT,
    ],
  },
  ORANGE: {
    color: "orange",
    tags: [
      Tags.SANCTIONED,
      Tags.PEP,
      Tags.MEDIUM_RISK,
      Tags.SOF_REQUIRED,
      Tags.POA_REQUIRED,
      Tags.POI_REQUIRED,
      Tags.POF_REQUIRED,
    ],
  },
};

export const getTagColor = (tag: Tags): string => {
  const colorKey = Object.keys(TagsColors).find((key: string) => {
    return TagsColors[key].tags.includes(tag);
  });

  return colorKey ? TagsColors[colorKey].color : "primary";
};

export class Tag {
  id: number;
  name: string;
  description: string;
}

import React, { FunctionComponent, useEffect, useState } from "react";
import urls from "src/utilities/urls";
import { useHistory } from "react-router-dom";
import { ButtonGroup, Button } from "react-bootstrap";
import Swal, { SweetAlertResult } from "sweetalert2";
import { CategoryUpdateModal } from "../CategoryUpdateModal";
import { Category } from "src/models/app/Category";
import { RequestUpdateCategoryDto } from "src/models/dto/category.dto";
import { makeRequest } from "src/utilities/axio.helper";
import { safeFormatDate } from "src/utilities/date";

export interface ICategoryItemProps {
  category: Category;
  onUpdated: (category: Category) => void;
  onDeleted: (category: Category) => void;
}

export const CategoryListItem: FunctionComponent<ICategoryItemProps> = (
  props: ICategoryItemProps,
) => {
  const isCategoryDeleted = (category: Category): boolean =>
    category.deletedAt === null;
  const [showUpdateModal, setShowUpdateModal] = useState<boolean>(false);
  const [category, setCategory] = useState<Category>(new Category());
  const history = useHistory();
  const showUpdateCategoryModal = (): void => setShowUpdateModal(true);
  const hideUpdateCategoryModal = (): void => setShowUpdateModal(false);

  useEffect(() => {
    setCategory(props.category);
  }, [props.category]);

  const getUpdateCategoryModal = (): React.ReactChild => {
    if (showUpdateModal && props.category) {
      return (
        <CategoryUpdateModal
          category={props.category}
          removeModal={hideUpdateCategoryModal}
          onUpdate={props.onUpdated}
        />
      );
    }

    return <></>;
  };

  const toggleCategory = async (active: boolean): Promise<void> => {
    let title = "Are you sure you want to deactivate this category?";
    if (active) {
      title = "Are you sure you want to activate this category?";
    }

    const result: SweetAlertResult = await Swal.fire({
      title: title,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes!",
    });

    if (result.isConfirmed) {
      try {
        category.active = active ? 1 : 0;
        await makeRequest<Category, RequestUpdateCategoryDto>(
          "post",
          urls.CATEGORIES,
          {
            id: category.id,
            name: category.name,
            code: category.code,
            active: category.active,
          },
        );

        if (active) {
          toastr.success("Activated Category!");
        } else {
          toastr.success("Deactivated Category!");
        }

        props.onDeleted(props.category);
      } catch (err) {
        toastr.error(err.message);
      }
    }
  };

  return (
    <>
      <tr key={props.category.id}>
        <td>{props.category.id}</td>
        <td>
          {props.category.name}{" "}
          {!isCategoryDeleted(props.category) ? " (Deleted)" : ""}
        </td>
        <td>{props.category.code}</td>
        <td>{props.category.active}</td>
        <td>{safeFormatDate(props.category.createdAt)}</td>
        <td>{safeFormatDate(props.category.updatedAt)}</td>
        <td>
          <ButtonGroup className="float-sm-right" aria-label="Basic example">
            {!(props.category.active === 0) ? (
              <Button variant="danger" onClick={() => toggleCategory(false)}>
                Deactivate
              </Button>
            ) : (
              <Button variant="primary" onClick={() => toggleCategory(true)}>
                Activate
              </Button>
            )}
          </ButtonGroup>
          <ButtonGroup
            className="float-sm-right mr-3"
            aria-label="Basic example"
          >
            <Button variant="info" onClick={showUpdateCategoryModal}>
              Update
            </Button>
          </ButtonGroup>
          <ButtonGroup
            className="float-sm-right mr-3"
            aria-label="Basic example"
          >
            <Button
              variant="secondary"
              onClick={() =>
                history.push(`/categories/gamelist/${props.category.id}`)
              }
            >
              Games
            </Button>
          </ButtonGroup>
        </td>
      </tr>
      {getUpdateCategoryModal()}
    </>
  );
};

/**
    //////////////////////////////////////////////////////////////////////
    THIS FILE WAS GENEREATED
    Run 'npm run export-models' on backend to sync models
    ((DO NOT MODIFIY!!!))
    //////////////////////////////////////////////////////////////////////
    */

/* -------------------------------- Models -------------------------------- */
import { Game } from "src/models/app/Game";

export class GetGamesWhereDto {
  code?: string[];

  id?: number[];

  name?: string[];

  active?: number;

  providers?: number[];

  categories?: number[];

  themes?: number[];

  primaryCategories?: string[];
}

export class RequestGetGamesDto {
  where?: GetGamesWhereDto;

  offset?: number;

  limit?: number;

  withDeleted?: boolean;
}
export class ResponseGetGamesDto {
  games: Game[];
  totalGames: number;
}

export class UpdateGameDto {
  id: number;

  name?: string;

  code?: string;

  active?: number;

  rating?: number;

  providerWeight?: number;

  releasedAt?: Date;

  primaryCategoryId?: number;

  providerId?: number;

  gameCategorieIds?: number[];

  gameThemesIds?: number[];

  rtp?: number;
}
export class ManuallyAddedGame {
  name: string;

  externalId: string;

  providerId: number;

  active: number = 0;
}
export class DeleteGameDto {
  id: number;
}

export class RequestUpdateGamesDto {}
export class ResponseUpdateGamesDto {
  message: string;
  newGameCount: number;
}

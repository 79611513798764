/* -------------------React ------------------ */
import React, { FunctionComponent, useState } from "react";
import { Route, Switch, useHistory } from "react-router-dom";

/* ----------- Pages ---------------- */
import GameList from "../GameListPage/GameList";
import AddGameManuallyPage from "../AddGamesManuallyPage.tsx/AddGameManuallyPage";
import GameEditPage from "../GameEditPage/GameEditPage";

/* ------------ Utility --------------*/
import { Jumbotron, Button } from "react-bootstrap";
import { makeRequest } from "../../../utilities/axio.helper";

/* -------------- Config -------*/
import config from "src/config";
import urls from "../../../utilities/urls";

/* ----------- Models/Interface --------------- */
import { Game } from "../../../models/app/Game";
import {
  DeleteGameDto,
  RequestUpdateGamesDto,
  ResponseUpdateGamesDto,
} from "src/models/dto/game.dto";
import SpinnerComp from "src/components/spinner/SpinnerComp";

const GamesPage: FunctionComponent = () => {
  const history = useHistory();
  const [updatingGames, setUpdatingGames] = useState<boolean>(false);

  const deleteGame = async (gameId: number): Promise<void> => {
    try {
      await makeRequest<Game, DeleteGameDto>("delete", urls.GAMES_LIST, {
        id: gameId,
      });
    } catch (err) {
      toastr.error((err as any).message);
    }
  };

  const addGameManually = async (): Promise<void> => {
    try {
      history.push("/games/add");
    } catch (err) {
      toastr.error((err as Error).message);
    }
  };

  const updateGames = async (): Promise<void> => {
    try {
      setUpdatingGames(true);
      const response = await makeRequest<
        ResponseUpdateGamesDto,
        RequestUpdateGamesDto
      >(
        "post",
        urls.APP_CASINO_UPDATE_GAMES,
        undefined,
        {
          username: config.API_AUTH.USERNAME,
          password: config.API_AUTH.PASSWORD,
        },
        config.BACKEND_API_URL,
      );

      setUpdatingGames(false);
      toastr.success(
        `${response.message} (New Games: ${response.newGameCount})`,
      );
    } catch (err) {
      setUpdatingGames(false);
      toastr.error((err as any).message);
    }
  };

  return (
    <div>
      <Switch>
        <Route path="/games/edit/:gameId?">
          <GameEditPage />
        </Route>
        <Route path="/games/add/">
          <AddGameManuallyPage />
        </Route>
        <Route path="/games">
          <Jumbotron className="m-4">
            <h2 className="ml-4 mb-4">Game List</h2>
            <div className="ml-4">
              <Button
                style={{ height: "3rem", minWidth: "10%" }}
                className="mb-4"
                onClick={() => updateGames()}
              >
                {updatingGames ? (
                  <SpinnerComp animation="border" />
                ) : (
                  "Update Games List"
                )}
              </Button>{" "}
              <Button
                style={{ height: "3rem", minWidth: "10%" }}
                className="mb-4 ml-4"
                onClick={() => addGameManually()}
              >
                Add Game Manually
              </Button>
            </div>
            <GameList deleteGame={deleteGame}></GameList>
          </Jumbotron>
        </Route>
      </Switch>
    </div>
  );
};

export default GamesPage;

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { FunctionComponent, useState, useEffect } from "react";
import DayJS from "dayjs";
import { Exclusion } from "src/models/app/Exclusion";

import { Button, ButtonGroup } from "react-bootstrap";
import { ExclusionTypes } from "src/models/app/ExclusionType";
import Swal, { SweetAlertResult } from "sweetalert2";
import { RequestUpdateExclusionDto } from "src/models/dto/exclusion.dto";
import { makeRequest } from "src/utilities/axio.helper";
import urls from "src/utilities/urls";
import { safeFormatDate } from "src/utilities/date";

export interface IAccountExclusionItemProps {
  exclusionDto: Exclusion;
  accountId: number;
}

export const AccountExclusionItem: FunctionComponent<
  IAccountExclusionItemProps
> = ({ exclusionDto, accountId }: IAccountExclusionItemProps) => {
  const [exclusionType, setExclusionType] = useState<string>();

  useEffect(() => {
    setExclusionType(() => {
      if (exclusionDto.typeId === ExclusionTypes.DAY) return "Day";
      if (
        exclusionDto.typeId === ExclusionTypes.MONTH ||
        exclusionDto.typeId === ExclusionTypes.THREE_MONTHS ||
        exclusionDto.typeId === ExclusionTypes.SIX_MONTHS
      )
        return "Month";
      if (exclusionDto.typeId === ExclusionTypes.PERMANENT) return "Permanent";
    });
  }, [exclusionDto]);

  const disableExclusion = async (): Promise<void> => {
    const title: string = "Are you sure you want to disable this exclusion?";

    const result: SweetAlertResult = await Swal.fire({
      title: title,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes!",
    });

    if (result.isConfirmed) {
      try {
        await makeRequest<Exclusion, RequestUpdateExclusionDto>(
          "post",
          urls.EXCLUSIONS_DISABLE,
          {
            accountId: accountId,
          },
        );

        toastr.success("Exclusion disable");
      } catch (err) {
        toastr.error(err.message);
      }
    }
  };

  return (
    <tr>
      <td>{exclusionType}</td>
      <td>{exclusionDto.active ? "Yes" : "No"}</td>
      <td>{safeFormatDate(exclusionDto.startDate)}</td>
      <td>{safeFormatDate(exclusionDto.endDate)}</td>
      <td>
        <ButtonGroup>
          {exclusionDto.active && (
            <Button variant="danger" onClick={disableExclusion}>
              Disable
            </Button>
          )}
        </ButtonGroup>
      </td>
    </tr>
  );
};

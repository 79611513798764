import React, { FunctionComponent, useState, useEffect } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { RequestUpdateGameCategoryDto } from "src/models/dto/game.category.dto";
import { makeRequest } from "src/utilities/axio.helper";
import urls from "src/utilities/urls";
import { GameCategory } from "src/models/app/GameCategory";

export interface IGameCategoryUpdateModal {
  gameCategory: GameCategory;
  removeModal: () => void;
  onUpdate: (gameCategory: GameCategory) => void;
}

export const GameCategoryUpdateModal: FunctionComponent<
  IGameCategoryUpdateModal
> = (props: IGameCategoryUpdateModal) => {
  const [updatedCategory, setUpdatedCategory] = useState<GameCategory>(
    new GameCategory(),
  );

  useEffect(() => {
    setUpdatedCategory(props.gameCategory);
  }, [props.gameCategory]);

  const onHide = (): void => props.removeModal();

  const onSave = async (): Promise<void> => {
    try {
      // Create Limit
      await makeRequest<GameCategory, RequestUpdateGameCategoryDto>(
        "post",
        urls.GAME_CATEGORIES,
        {
          ...updatedCategory,
        },
      );

      toastr.success("Updated Game Category!");

      props.onUpdate(updatedCategory);
      onHide();
    } catch (err) {
      toastr.error(err.message);
    }
  };

  const renderModal = (): React.ReactChild => {
    return (
      <Modal show={true} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Update Game Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="weight">
              <Form.Label>Weight</Form.Label>
              <Form.Control
                type="number"
                value={updatedCategory.weight}
                onChange={(e) => {
                  setUpdatedCategory({
                    ...updatedCategory,
                    weight: Number(e.target.value),
                  });
                }}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={onSave}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  return <>{renderModal()}</>;
};

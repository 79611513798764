import React, { FunctionComponent, useEffect, useState } from "react";
import { BonusGame } from "src/models/app/BonusGame";
import { Game } from "../../../models/app/Game";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { Button, Card, Col, Form, Tabs, Tab } from "react-bootstrap";
import toastr from "toastr";
import { makeRequest } from "../../../utilities/axio.helper";
import config from "src/config";
import urls from "src/utilities/urls";
import { BonusType } from "../../../models/app/BonusType";

const BonusGamesEditPage: FunctionComponent = () => {
  const history = useHistory();
  const location = useLocation();
  const [bonusGame, setBonusGame] = useState<BonusGame>(new BonusGame());
  const [bonusGames, setBonusGames] = useState<BonusGame[]>([]);
  const [game, setGame] = useState<Game>(new Game());
  const [games, setGames] = useState<Game[]>([]);
  const [bonusTypes, setBonusTypes] = useState<BonusType[]>([]);
  const { gameId } = useParams();

  useEffect(() => {
    const getGame = async (): Promise<void> => {
      try {
        const response = await makeRequest(
          "post",
          `${config.API_URL}/bonus/games`,
          {
            id: [Number(gameId)],
          },
          {
            username: config.API_AUTH.USERNAME,
            password: config.API_AUTH.PASSWORD,
          },
        );

        if (location) {
          const id = location.search.replace("?", "");
          const respBonusGame = response.games.filter(
            (gm: BonusGame) => gm.bonusTypeId === Number(id),
          )[0];

          setBonusGame(respBonusGame);
          setGame(respBonusGame?.game);
        } else {
          const respBonusGame = response.games[0];
          setBonusGame(respBonusGame);
          setGame(respBonusGame?.game);
        }
      } catch (err) {
        console.log(err);
      }
    };
    if (gameId) {
      getGame();
    }
  }, [gameId, location]);

  useEffect(() => {
    const getData = async (): Promise<void> => {
      try {
        const response = await makeRequest(
          "post",
          `${config.API_URL}/bonus/types`,
          undefined,
          {
            username: config.API_AUTH.USERNAME,
            password: config.API_AUTH.PASSWORD,
          },
        );

        const respBonusTypes = response[0];

        const existedBonusGame = bonusGames.filter(
          (existed) => existed.gameId === game.id,
        );
        const existedBonusType = existedBonusGame.map((ebg) => ebg.bonusType);

        let availableBonusTypesTemp: BonusType[] = [];

        if (existedBonusType.length > 0) {
          respBonusTypes.filter((bt: BonusType) => {
            return existedBonusType.filter((ebt) => {
              if (bt.id !== ebt.id) {
                availableBonusTypesTemp.push(bt);
              }
              return availableBonusTypesTemp;
            });
          });
        } else {
          availableBonusTypesTemp = respBonusTypes;
        }

        const availableBonusTypes = availableBonusTypesTemp;
        setBonusTypes(availableBonusTypes);

        if (!gameId && !bonusGame?.gameId) {
          const getBonusGames = await makeRequest(
            "post",
            `${config.API_URL}/bonus/games`,
            {
              where: { active: 1 },
            },
            {
              username: config.API_AUTH.USERNAME,
              password: config.API_AUTH.PASSWORD,
            },
          );

          const getGamesResponse = await makeRequest("post", urls.GAMES_LIST, {
            where: { active: 1 },
          });

          const availableGames = getDifferenceGames(
            getGamesResponse.games,
            getBonusGames.games,
            respBonusTypes,
          );

          setBonusGames(getBonusGames.games);

          if (!bonusGame.game) {
            setBonusGame({
              ...bonusGame,
              game: availableGames[0],
              gameId: availableGames[0].id,
              bonusTypeId: availableBonusTypes[0]?.id,
            });
            setGame(availableGames[0]);
          }
          setGames(availableGames || []);
        }
      } catch (err) {
        console.log(err.message);
      }
    };

    getData();
  }, [gameId, bonusGame, bonusGames, game]);

  const getDifferenceGames = (
    allGames: Game[],
    bonusGames: BonusGame[],
    bonusTypesResp: BonusType[],
  ) => {
    const result = allGames;
    allGames.filter((ag) => {
      return bonusGames.filter((bg) => {
        if (
          ag.id === bg.gameId &&
          bonusGames.filter((ga) => ga.gameId === ag.id).length ===
            bonusTypesResp.length
        ) {
          const index = result.indexOf(ag);
          result.splice(index, 1);
        }
        return result;
      });
    });
    return result;
  };

  const saveGame = async (): Promise<void> => {
    try {
      const onlyBonusGameData = (({ game, bonusType, ...bg }) => bg)(bonusGame);
      onlyBonusGameData.bonusTypeId =
        bonusTypes.length < 2
          ? bonusTypes[0].id
          : onlyBonusGameData.bonusTypeId;
      await makeRequest(
        "post",
        `${config.API_URL}/bonus/update/game`,
        {
          bonusGame: onlyBonusGameData,
        },
        {
          username: config.API_AUTH.USERNAME,
          password: config.API_AUTH.PASSWORD,
        },
      );

      toastr.success(`Game ${game.name} Updated!`);

      history.push("/bonus-game");
    } catch (err) {
      toastr.error(err.message);
    }
  };

  const checkData = () => {
    if (!gameId && game?.externalId === undefined) return true;
    if (
      !bonusGame ||
      !bonusGame.bonusTypeId ||
      !bonusGame.gameId ||
      !bonusGame.wageringPercentage
    )
      return true;
    return false;
  };

  return (
    <Tabs defaultActiveKey="details" id="uncontrolled-tab-example">
      <Tab eventKey="details" title="Details">
        <Card>
          <Card.Header>
            {gameId === undefined ? "Create" : "Edit"} Game
          </Card.Header>
          <Card.Body>
            <Form>
              <Form.Row>
                <Form.Group as={Col} controlId="formName">
                  <Form.Label>Name</Form.Label>
                  {gameId ? (
                    <Form.Control
                      readOnly
                      type="text"
                      placeholder="Enter game name"
                      value={game?.name}
                    />
                  ) : (
                    <Form.Control
                      as="select"
                      custom
                      value={game?.name}
                      onChange={(
                        evt: React.ChangeEvent<HTMLInputElement>,
                      ): void => {
                        if (evt.target) {
                          const gameName = evt.target.value;
                          const newBonusGame: Game | undefined =
                            games.find((g) => g.name === gameName) ||
                            new Game();
                          setGame(newBonusGame);
                          setBonusGame({
                            ...bonusGame,
                            gameId: newBonusGame.id,
                            game: newBonusGame,
                          });
                        }
                      }}
                    >
                      {games?.map((value: Game) => (
                        <option key={value.id} value={value.name}>
                          {value.name} - {value.externalId}
                        </option>
                      ))}
                    </Form.Control>
                  )}
                </Form.Group>

                <Form.Group as={Col} controlId="formGameCategories">
                  <Form.Label>Game Categories</Form.Label>
                  <Form.Control
                    readOnly
                    type="text"
                    placeholder="Enter game category"
                    value={"Bonus Games"}
                  />
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col} controlId="formCode">
                  <Form.Label>Code</Form.Label>
                  <Form.Control
                    readOnly
                    type="text"
                    placeholder="Game Code"
                    value={game?.code}
                    onChange={(
                      event: React.ChangeEvent<HTMLInputElement>,
                    ): void =>
                      setGame({
                        ...game,
                        code: event.target.value,
                      })
                    }
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="formExternalId">
                  <Form.Label>External Id</Form.Label>
                  <Form.Control
                    readOnly
                    type="text"
                    placeholder="Game external id"
                    value={game?.externalId}
                    onChange={(
                      event: React.ChangeEvent<HTMLInputElement>,
                    ): void =>
                      setGame({
                        ...game,
                        externalId: event.target.value,
                      })
                    }
                  />
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col} controlId="formCode">
                  <Form.Label>Wagering %</Form.Label>
                  <Form.Control
                    type="number"
                    step="any"
                    min="0"
                    max="100"
                    value={bonusGame?.wageringPercentage}
                    onChange={(
                      evt: React.ChangeEvent<HTMLInputElement>,
                    ): void => {
                      const newWagering = Number(
                        parseFloat(evt.target.value).toFixed(2),
                      );

                      setBonusGame({
                        ...bonusGame,
                        wageringPercentage:
                          newWagering > 100 ? 100 : newWagering,
                      });
                    }}
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="formBonusType">
                  <Form.Label>Bonus Types</Form.Label>
                  {gameId ? (
                    <Form.Control
                      readOnly
                      type="text"
                      placeholder="Enter bonus type"
                      value={bonusGame?.bonusType?.description}
                    />
                  ) : (
                    <Form.Control
                      as="select"
                      onChange={(
                        evt: React.ChangeEvent<HTMLInputElement>,
                      ): void => {
                        if (evt.target) {
                          const newBonusType: BonusType =
                            bonusTypes?.find(
                              (type) => type.id === Number(evt.target.value),
                            ) || bonusGame.bonusType;

                          setBonusGame({
                            ...bonusGame,
                            bonusTypeId: newBonusType.id,
                            bonusType: newBonusType,
                          });
                        }
                      }}
                    >
                      {bonusTypes?.map((bonusType: BonusType) => {
                        return (
                          <option key={bonusType.id} value={bonusType.id}>
                            {bonusType.description}
                          </option>
                        );
                      })}
                    </Form.Control>
                  )}
                </Form.Group>
              </Form.Row>

              <Button
                variant="primary"
                onClick={saveGame}
                disabled={checkData()}
              >
                Save
              </Button>
            </Form>
          </Card.Body>
        </Card>
      </Tab>
    </Tabs>
  );
};

export default BonusGamesEditPage;

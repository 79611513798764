import React, { FunctionComponent } from "react";
import { Account } from "../../../models/app/Account";
import { Table, Button } from "react-bootstrap";
import Moment from "moment";

interface IAccountTable {
  accounts: Account[];
}

const AccountTable: FunctionComponent<IAccountTable> = (
  props: IAccountTable,
) => {
  return (
    <Table hover className="bg-white">
      <thead>
        <tr className="text-center">
          <th>#</th>
          <th>CPR No.</th>
          <th>Full Name</th>
          <th>Email</th>
          <th>Phone</th>
          <th>Active</th>
          <th>Test Account</th>
          <th>DOB</th>
          <th>Options</th>
        </tr>
      </thead>
      <tbody>
        {props.accounts.map((account: Account, index) => {
          return (
            <tr key={index} className={"text-center"}>
              <td>{account.id}</td>
              <td>{account.cprNumber}</td>
              <td>{account.name}</td>
              <td>{account.email}</td>
              <td>{account.phoneNumber}</td>
              <td className={account.active ? "text-success" : "text-danger"}>
                {account.active ? "YES" : "NO"}
              </td>
              <td>{account.isTest ? "YES" : "NO"}</td>
              <td>{Moment(account.dateOfBirth).format("MM/DD/YYYY")}</td>
              <td>
                <Button
                  className="mr-1"
                  href={`/accounts/edit/${account.id}`}
                  variant="primary"
                >
                  Edit
                </Button>
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

export default AccountTable;

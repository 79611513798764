import React, { FunctionComponent } from "react";
import BonusGamesList from "../BonusGamesListPage/BonusGamesList";
import { Game } from "../../../models/app/Game";
import { Route, Switch, useHistory } from "react-router-dom";
import BonusGamesEditPage from "../BonusGamesEditPage/BonusGamesEditPage";
import urls from "../../../utilities/urls";
import { makeRequest } from "../../../utilities/axio.helper";
import { DeleteGameDto } from "src/models/dto/game.dto";
import { Jumbotron, Button } from "react-bootstrap";

const BonusGamesPage: FunctionComponent = () => {
  const history = useHistory();
  const deleteGame = async (gameId: number): Promise<void> => {
    try {
      await makeRequest<Game, DeleteGameDto>("delete", urls.GAMES_LIST, {
        id: gameId,
      });
    } catch (err) {
      toastr.error(err.message);
    }
  };

  const insertGame = async (): Promise<void> => {
    try {
      history.push("/bonus-game/edit");
    } catch (err) {
      toastr.error(err.message);
    }
  };

  return (
    <div>
      <Switch>
        <Route path="/bonus-game/edit/:gameId?">
          <BonusGamesEditPage />
        </Route>
        <Route path="/bonus-game">
          <Jumbotron className="m-4">
            <h3>Bonus Game List</h3>
            <Button onClick={() => insertGame()}>Insert Games</Button>
            <BonusGamesList deleteGame={deleteGame} />
          </Jumbotron>
        </Route>
      </Switch>
    </div>
  );
};

export default BonusGamesPage;

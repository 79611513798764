/* eslint-disable react-hooks/exhaustive-deps */
import React, { FunctionComponent, useEffect, useState } from "react";
import {
  Accordion,
  Button,
  Card,
  Form,
  Pagination,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { GetGamesWhereDto } from "src/models/dto/game.dto";
import { makeRequest } from "src/utilities/axio.helper";
import BonusGamesTable from "./BonusGamesTable";
import config from "src/config";
import { BonusGame } from "src/models/app/BonusGame";

interface IGameListProps {
  deleteGame: (gameId: number) => Promise<void>;
}

class FilterValues {
  id: number[] = [];
  name: string[] = [];
  showDeleted = true;
  showActive = true;
}

export async function refreshGames(
  filterValues: any,
  paginationShowAmount: any,
  paginationIndex: any,
  setPaginationTotalPages: any,
  setGames: any,
) {
  const where: GetGamesWhereDto = new GetGamesWhereDto();

  if (filterValues.id.length > 0) {
    where.id = filterValues.id;
  }

  if (filterValues.name.length > 0) {
    where.name = filterValues.name;
  }

  if (filterValues.showActive) {
    where.active = 1;
  } else {
    where.active = 0;
  }

  const getBonusGames = await makeRequest(
    "post",
    `${config.API_URL}/bonus/games`,
    {
      ...where,
      limit: paginationShowAmount,
      offset: (paginationIndex - 1) * paginationShowAmount,
    },
    {
      username: config.API_AUTH.USERNAME,
      password: config.API_AUTH.PASSWORD,
    },
  );

  setPaginationTotalPages(
    Math.ceil(getBonusGames.totalGames / paginationShowAmount),
  );

  const gameRows = [] as any;

  for (let i = 0; i < getBonusGames.games.length; i++) {
    gameRows.push(getBonusGames.games[i]);
  }

  setGames(gameRows);
}

const BonusGamesList: FunctionComponent<IGameListProps> = () => {
  const [paginationIndex, setPaginationIndex] = useState<number>(1);
  const [paginationTotalPages, setPaginationTotalPages] = useState<number>(0);
  const [paginationShowAmount] = useState<number>(8);
  const [games, setGames] = useState<BonusGame[]>([]);

  const [filterValues, setFilterValues] = useState<FilterValues>(
    new FilterValues(),
  );

  useEffect(() => {
    refreshGames(
      filterValues,
      paginationShowAmount,
      paginationIndex,
      setPaginationTotalPages,
      setGames,
    );
  }, []);

  useEffect(() => {
    setPaginationIndex(1);
    refreshGames(
      filterValues,
      paginationShowAmount,
      paginationIndex,
      setPaginationTotalPages,
      setGames,
    );
  }, [filterValues]);

  useEffect(() => {
    refreshGames(
      filterValues,
      paginationShowAmount,
      paginationIndex,
      setPaginationTotalPages,
      setGames,
    );
  }, [paginationIndex]);

  const getPagination = (): React.ReactChild => {
    const items = [];
    const filteredItems = [];
    let prevIndex = 0;
    let nextIndex = 10;

    for (let number = 1; number <= paginationTotalPages; number++) {
      items.push(
        <Pagination.Item
          key={number}
          active={number === paginationIndex}
          onClick={(): void => setPaginationIndex(number)}
        >
          {number}
        </Pagination.Item>,
      );
    }

    if (paginationIndex - 10 > 0) {
      prevIndex = paginationIndex - 10;
    }

    const triggeringIndex = Math.ceil(nextIndex / 2) + 1;

    if (paginationIndex > triggeringIndex) {
      nextIndex = paginationIndex + 4;
      prevIndex = paginationIndex - 6;
    }

    for (let index = prevIndex; index <= nextIndex; index++) {
      filteredItems.push(items[index]);
    }

    return (
      <Pagination className="m-4 flex-wrap">
        <Pagination.First onClick={(): void => setPaginationIndex(1)} />
        <Pagination.Prev
          onClick={(): void =>
            setPaginationIndex(
              paginationIndex !== 1 ? paginationIndex - 1 : paginationIndex,
            )
          }
        />
        {filteredItems}
        <Pagination.Next
          onClick={(): void =>
            setPaginationIndex(
              paginationIndex !== items.length
                ? paginationIndex + 1
                : paginationIndex,
            )
          }
        />
        <Pagination.Last
          onClick={(): void => {
            setPaginationIndex(items.length);
            getPagination();
          }}
        />
      </Pagination>
    );
  };

  return (
    <>
      <Card className="m-4">
        <Card.Header>
          <Accordion.Toggle as={Button} variant="link" eventKey="0">
            Filter
          </Accordion.Toggle>
        </Card.Header>
        <Card.Body>
          <Form
            onSubmit={(e: React.FormEvent<HTMLElement>): void =>
              e.preventDefault()
            }
          >
            <Form.Group controlId="id">
              <Form.Label>Game Id</Form.Label>
              <Form.Control
                type="text"
                placeholder="Game Id"
                onChange={(evt: React.ChangeEvent<HTMLInputElement>): void => {
                  let id = undefined;
                  if (evt.target.value) {
                    id = evt.target.value;
                  }

                  let ids: number[] = [];
                  if (id) {
                    ids = id?.split(",").map((id) => Number(id));
                  }

                  setFilterValues({
                    ...filterValues,
                    id: ids,
                  });
                }}
              />
            </Form.Group>
            <Form.Group controlId="name">
              <Form.Label>Game Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Deadwood"
                onChange={(evt: React.ChangeEvent<HTMLInputElement>): void => {
                  let name = undefined;
                  if (evt.target.value) {
                    name = evt.target.value;
                  }

                  let names: string[] = [];

                  if (name) {
                    names = name
                      ?.split(",")
                      .map((name) => "%" + name.trim() + "%");
                  }

                  setFilterValues({
                    ...filterValues,
                    name: names,
                  });
                }}
              />
            </Form.Group>
            <Row>
              {/* <Col>
                <Form.Group controlId="showDeleted">
                  <Form.Label>Show Deleted?</Form.Label>
                  <Form.Control
                    type="checkbox"
                    defaultChecked={filterValues.showDeleted}
                    onChange={(
                      evt: React.ChangeEvent<HTMLInputElement>,
                    ): void => {
                      setFilterValues({
                        ...filterValues,
                        showDeleted: evt.target.checked,
                      });
                    }}
                    style={{ width: "20px" }}
                  />
                </Form.Group>
              </Col> */}
              <Col>
                <Form.Group controlId="showActive">
                  <Form.Label>Show Only Active?</Form.Label>
                  <Form.Control
                    type="checkbox"
                    defaultChecked={filterValues.showActive}
                    onChange={(
                      evt: React.ChangeEvent<HTMLInputElement>,
                    ): void => {
                      setFilterValues({
                        ...filterValues,
                        showActive: evt.target.checked,
                      });
                    }}
                    style={{ width: "20px" }}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>

      {getPagination()}
      <Container className="px-4 mw-none w-100">
        <BonusGamesTable
          categories={[]}
          games={games}
          filterValues={filterValues}
          paginationShowAmount={paginationShowAmount}
          paginationIndex={paginationIndex}
          setPaginationTotalPages={setPaginationTotalPages}
          setGames={setGames}
        />
      </Container>
    </>
  );
};

export default BonusGamesList;

const ACCOUNTS_PREFIX = "/accounts";
const LOGIN_HISTORY_PREFIX = "/login-history";
const ACCOUNT_STATUS_PREFIX = "/account-status";
const ACCOUNT_TAGS_PREFIX = "/tags";
const GAMES_PREFIX = "/games";
const CATEGORIES_PREFIX = "/categories";
const THEMES_PREFIX = "/themes";
const GAME_CATEGORIES_PREFIX = "/gamecategories";
const GAME_THEMES_PREFIX = "/gamethemes";

const CURRENCIES_PREFIX = "/currencies";
const COUNTRIES_PREFIX = "/countries";
const LANGUAGES_PREFIX = "/languages";
const PROVIDERS_PREFIX = "/providers";
const LIMIT_PREFIX = "/limits";
const EXCLUSION_PREFIX = "/exclusions";
const DEPOSIT_LIMITS_PREFIX = "/deposit-limits";
const NOLIMITS = "/nolimit";

const COMMENT_PREFIX = "/comments";
const TRANSACTION_PREFIX = "/transactions";
const AUTH_PREFIX = "/auth";
const PAYMENT_ACCOUNT_PREFIX = "/payment-account";

const BACK_OFFICE_ACCOUNT_PREFIX = "accounts/back-office-accounts";
const API_PREFIX = "https://api-staging.casinoepik.dk";

const DOCUMENT_PREFIX = "/documents";

export default {
  CURRENCIES: `${CURRENCIES_PREFIX}`,
  CURRENCIES_LIST: `${CURRENCIES_PREFIX}/list`,

  COUNTRIES: `${COUNTRIES_PREFIX}`,
  COUNTRIES_LIST: `${COUNTRIES_PREFIX}/list`,

  LANGUAGES: `${LANGUAGES_PREFIX}`,
  LANGUAGES_LIST: `${LANGUAGES_PREFIX}/list`,

  GAMES_ADD_MANUAL: `${GAMES_PREFIX}/add/manual-game`,
  GAMES_UPDATE: `${GAMES_PREFIX}/update`,
  GAMES_LIST: `${GAMES_PREFIX}/list`,

  CATEGORIES: `${CATEGORIES_PREFIX}`,
  CATEGORIES_LIST: `${CATEGORIES_PREFIX}/list`,

  GAME_CATEGORIES: `${GAME_CATEGORIES_PREFIX}`,
  GAME_CATEGORIES_LIST: `${GAME_CATEGORIES_PREFIX}/list`,

  THEMES: `${THEMES_PREFIX}`,
  THEMES_LIST: `${THEMES_PREFIX}/list`,

  GAME_THEMES: `${GAME_THEMES_PREFIX}`,
  GAME_THEMES_LIST: `${GAME_THEMES_PREFIX}/list`,

  LOGIN_HISTORY: `${LOGIN_HISTORY_PREFIX}/history`,
  GET_ERROR_CODES: `${LOGIN_HISTORY_PREFIX}/get-error-codes`,

  PROVIDERS: `${PROVIDERS_PREFIX}/list`,
  PROVIDERS_UPDATE: `${PROVIDERS_PREFIX}/update`,
  PROVIDERS_MANUAL_LIST: `${PROVIDERS_PREFIX}/list/manual`,

  ACCOUNTS_UPDATE: `${ACCOUNTS_PREFIX}/update`,
  ACCOUNTS_LIST: `${ACCOUNTS_PREFIX}/list`,
  ACCOUNTS_GET: `${ACCOUNTS_PREFIX}/get`,
  ACCOUNTS_LOGIN_INFO: `${ACCOUNTS_PREFIX}/login-info`,
  ACCOUNTS_CREATE_TEST: `${ACCOUNTS_PREFIX}/create-test-account`,

  ACCOUNT_STATUS: `${ACCOUNT_STATUS_PREFIX}`,
  ACCOUNT_STATUS_LIST: `${ACCOUNT_STATUS_PREFIX}/list`,
  ACCOUNT_MONETARY_TRANSACTIONS: `${ACCOUNTS_PREFIX}/transactions`,
  ACCOUNT_GAME_TRANSACTIONS: `${ACCOUNTS_PREFIX}/game-transactions`,
  ACCOUNT_BONUS_TRANSACTIONS: `${ACCOUNTS_PREFIX}/bonus-transactions`,
  ACCOUNT_PAYMENTS: `${ACCOUNTS_PREFIX}/get-payments-account`,
  EXCLUSIONS_LIST: `${EXCLUSION_PREFIX}/list`,
  EXCLUSIONS_CREATE: `${EXCLUSION_PREFIX}/create`,
  EXCLUSIONS_DISABLE: `${EXCLUSION_PREFIX}/disable`,

  ACCOUNT_TAGS_LIST: `${ACCOUNT_TAGS_PREFIX}/list`,
  ACCOUNT_TAGS_ADD: `${ACCOUNT_TAGS_PREFIX}/add`,
  ACCOUNT_TAGS_REMOVE: `${ACCOUNT_TAGS_PREFIX}/remove`,
  KPI: `${ACCOUNTS_PREFIX}/kpi`,
  KPI_DETAILS: `${ACCOUNTS_PREFIX}/kpi/details`,
  STATUS_LOGS: `${ACCOUNTS_PREFIX}/status-logs`,

  LIMITS: `${LIMIT_PREFIX}`,
  LIMITS_LIST: `${LIMIT_PREFIX}/list`,
  DEPOSIT_LIMITS: `${DEPOSIT_LIMITS_PREFIX}`,

  COMMENTS: `${COMMENT_PREFIX}/create`,
  COMMENTS_LIST: `${COMMENT_PREFIX}/list`,

  TRANSACTIONS: `${TRANSACTION_PREFIX}`,
  TRANSACTIONS_LIST: `${TRANSACTION_PREFIX}/list`,
  TRANSACTIONS_TOTAL: `${TRANSACTION_PREFIX}/totals`,
  TRANSACTIONS_PENDING: `${TRANSACTION_PREFIX}/pending`,
  TRANSACTIONS_AUTHORIZE: `${API_PREFIX}/admin/withdraw/authorize`,
  TRANSACTIONS_CANCEL: `${API_PREFIX}/admin/withdraw/cancel`,
  TRANSACTIONS_REFUND: `${API_PREFIX}/admin/withdraw/refund`,
  TRANSACTIONS_WITHDRAW: `${API_PREFIX}/admin/withdraw`,
  MANUAL_TRANSACTIONS: `${TRANSACTION_PREFIX}/manual-create`,
  MANUAL_TRANSACTIONS_LIST: `${TRANSACTION_PREFIX}/manual-transactions`,
  MANUAL_TRANSACTIONS_APPROVE: `${TRANSACTION_PREFIX}/manual-approve`,
  MANUAL_TRANSACTIONS_REJECT: `${TRANSACTION_PREFIX}/manual-reject`,

  AUTH_LOGIN: `${AUTH_PREFIX}/login`,
  AUTH_VERIFY_SESSION_TOKEN: `${AUTH_PREFIX}/verifyToken`,

  GET_ACCOUNT_ROLES: `${BACK_OFFICE_ACCOUNT_PREFIX}/roles`,
  BACK_OFFICE_ACCOUNT_LIST: `${AUTH_PREFIX}/list`,
  BACK_OFFICE_ACCOUNT_UPDATE_ROLES: `${AUTH_PREFIX}/set-role`,
  BACK_OFFICE_ACCOUNT_ROLE_LIST: `${AUTH_PREFIX}/role/list`,
  BACK_OFFICE_ACCOUNT_ROLES: `${BACK_OFFICE_ACCOUNT_PREFIX}/roles`,

  APP_CASINO_UPDATE_GAMES: `casino/games/update`,

  DOCUMENTS_REJECT: `${DOCUMENT_PREFIX}/reject`,
  DOCUMENTS_VERIFY: `${DOCUMENT_PREFIX}/verify`,
  DOCUMENTS_LIST: `${DOCUMENT_PREFIX}/list`,
  DOCUMENTS_UPLOAD: `${DOCUMENT_PREFIX}/upload`,
  DOCUMENTS_UPDATE: `${DOCUMENT_PREFIX}/update-document`,
  DOCUMENTS_DELETE: `${DOCUMENT_PREFIX}/delete-document`,

  PAYMENT_ACCOUNTS_LIST: `${PAYMENT_ACCOUNT_PREFIX}/list`,
  PAYMENT_ACCOUNTS_VERIFY: `${PAYMENT_ACCOUNT_PREFIX}/verify`,
  PAYMENT_ACCOUNTS_UNVERIFY: `${PAYMENT_ACCOUNT_PREFIX}/unverify`,

  NOLIMIT_GET_FREE_SPINS: `${NOLIMITS}/freebets/get`,
  NOLIMIT_ADD_FREE_SPINS: `${NOLIMITS}/freebets/add`,
  NOLIMIT_CANCEL_FREE_SPINS: `${NOLIMITS}/freebets/cancel`,

  METABASE_CLIENT_PROFILE: `http://reports.kanongaming.com:3000/dashboard/127-account?brand=Epik&account_id=`,
  METABASE_CLIENT_PROFILE_JOURNEY: `http://reports.kanongaming.com:3000/dashboard/162-account-aggregated-journey?brand=Epik&account_id=`,

  ROFUS_GAMBLER_EXCLUSION_STATUS: "/rofus/checkGamblerStatus",
};

import React, { FunctionComponent, useEffect, useState } from "react";
import { Card, Table } from "react-bootstrap";
import { useParams } from "react-router-dom";
import urls from "../../../utilities/urls";
import { Game } from "../../../models/app/Game";
import { makeRequest } from "../../../utilities/axio.helper";
import {
  GetGamesWhereDto,
  RequestGetGamesDto,
  ResponseGetGamesDto,
} from "src/models/dto/game.dto";

import ThemeGamesRow from "./ThemeGamesRow";
import {
  RequestGetThemeDto,
  ResponseGetThemeDto,
} from "src/models/dto/theme.dto";
import { Theme } from "src/models/app/Theme";

const ThemeGamesList: FunctionComponent = () => {
  const { themeId } = useParams<{ themeId: string }>();
  const themeIdInt = themeId ? parseInt(themeId) : 0;
  const [theme, setTheme] = useState<Theme>();
  const [games, setGames] = useState<Game[]>([]);

  function sortByWeight(a: Game, b: Game) {
    let gameA = a.gameThemes[0].weight;
    let gameB = b.gameThemes[0].weight;

    if (!gameA) gameA = 0;
    if (!gameB) gameB = 0;

    let comparison = 0;
    if (gameA > gameB) {
      comparison = -1;
    } else if (gameA < gameB) {
      comparison = 1;
    } else {
      comparison = 0;
    }
    return comparison;
  }

  useEffect(() => {
    const getData = async (): Promise<void> => {
      if (themeIdInt > 0 && games.length === 0) {
        try {
          const where: GetGamesWhereDto = new GetGamesWhereDto();
          where.themes = [themeIdInt];
          const getGamesResponse: ResponseGetGamesDto = await makeRequest<
            ResponseGetGamesDto,
            RequestGetGamesDto
          >("post", urls.GAMES_LIST, {
            where,
          });
          setGames(getGamesResponse.games.sort(sortByWeight));

          const getCurrentTheme: ResponseGetThemeDto = await makeRequest<
            ResponseGetThemeDto,
            RequestGetThemeDto
          >("post", urls.THEMES_LIST, {
            where: {
              id: [themeIdInt],
            },
          });
          setTheme(getCurrentTheme.themes[0]);
        } catch (err) {
          toastr.error(err.message);
        }
      }
    };

    getData();
  }, [themeIdInt, games]);

  return (
    <div>
      <Card>
        <Card.Header>
          <b>{theme?.name ?? ""}</b> Game Order By Weight
        </Card.Header>
        <Card.Body>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Weight</th>
                <th>Move</th>
                <th>Remove</th>
              </tr>
            </thead>
            <tbody>
              {games.map((game: Game, key: number) => (
                <ThemeGamesRow
                  game={game}
                  setGames={setGames}
                  key={key}
                  theme={theme}
                  index={key}
                  topWeight={games[0].gameThemes[0].weight}
                />
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </div>
  );
};

export default ThemeGamesList;

import React, {
  ChangeEvent,
  FunctionComponent,
  useEffect,
  useState,
} from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { makeRequest } from "src/utilities/axio.helper";
import { Account } from "src/models/app/Account";
import urls from "src/utilities/urls";
import { Comment, CommentAction, CommentType } from "src/models/app/Comment";
import { RequestUpdateCommentDto } from "src/models/dto/comment.dto";
import MultiSelect from "react-multi-select-component";
import { Option } from "react-multi-select-component/dist/lib/interfaces";

export interface IAccountLimitCreateModal {
  account: Account;
  removeModal: () => void;
  onSave: (comment: Comment) => void;
}

export const CreateAccountCommentModal: FunctionComponent<
  IAccountLimitCreateModal
> = (props: IAccountLimitCreateModal) => {
  const [newComment, setNewComment] = useState<string>("");
  const [selectedTypes, setSelectedTypes] = useState<string[]>([]);
  const [selectedTypesString, setSelectedTypesString] = useState<string>();
  const [selectedAction, setSelectedAction] = useState<string>();
  const [formValid, setFormValid] = useState<boolean>(false);

  useEffect(() => {
    if (
      selectedTypes === undefined ||
      selectedAction === undefined ||
      newComment === ""
    ) {
      setFormValid(false);
    } else {
      setSelectedTypesString(selectedTypes.toString().replace(",", ", "));
      setFormValid(true);
    }
  }, [selectedAction, selectedTypes, newComment]);

  const onHide = (): void => props.removeModal();

  const onSave = async (): Promise<void> => {
    try {
      if (formValid) {
        const comment: Comment = new Comment();
        comment.comment = newComment;
        comment.account = props.account;
        comment.type = selectedTypesString!;
        comment.action = selectedAction!;

        // Create Comment
        await makeRequest<Comment, RequestUpdateCommentDto>(
          "post",
          urls.COMMENTS,
          {
            comment: newComment,
            type: selectedTypesString,
            action: selectedAction,
            accountId: props.account.id,
          },
        );
        props.onSave(comment);
        toastr.success("Created comment!");
        onHide();
      }
    } catch (err) {
      toastr.error(err.message);
    }
  };

  const handleSelectedAction = (e: ChangeEvent<HTMLSelectElement>) =>
    setSelectedAction(e.target.value);

  const renderModal = (): React.ReactChild => {
    return (
      <Modal show={true} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Create Comment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="comment">
              <Form.Group style={{ display: "flex" }}>
                <Form.Label style={{ width: "10%", textAlign: "right" }}>
                  Type:{" "}
                </Form.Label>
                <div style={{ marginLeft: "1em", width: "60%" }}>
                  <MultiSelect
                    value={selectedTypes.map((type: string): Option => {
                      return { value: type, label: type };
                    })}
                    options={Object.values(CommentType).map(
                      (action: string): Option => {
                        return { label: action, value: action };
                      },
                    )}
                    onChange={(options: Option[]): void => {
                      const selectedTypes = options.map(
                        (option): string => option.value,
                      );
                      setSelectedTypes(selectedTypes);
                    }}
                    labelledBy={"actions"}
                  />
                </div>
              </Form.Group>
              <Form.Group style={{ display: "flex" }}>
                <Form.Label style={{ width: "10%", textAlign: "right" }}>
                  Action:{" "}
                </Form.Label>
                <select
                  style={{ marginLeft: "1em", width: "60%" }}
                  onChange={handleSelectedAction}
                  required
                >
                  <option value="" disabled selected hidden>
                    Select Comment Action
                  </option>
                  {Object.values(CommentAction).map((value) => (
                    <option
                      key={value}
                      value={value}
                      selected={value === selectedAction}
                    >
                      {value}
                    </option>
                  ))}
                </select>
              </Form.Group>
              <Form.Label>Comment</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                  setNewComment(e.target.value);
                }}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={onSave} disabled={!formValid}>
            Create
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  return <>{renderModal()}</>;
};

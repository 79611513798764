/**
    //////////////////////////////////////////////////////////////////////
    THIS FILE WAS GENEREATED
    Run 'npm run export-models' on backend to sync models
    ((DO NOT MODIFIY!!!))
    //////////////////////////////////////////////////////////////////////
    */

// Models
import { Transaction } from "./Transaction";

export enum TransactionTypes {
  SPIN = "SPIN",
  DEPOSIT = "DEPOSIT",
  WITHDRAW = "WITHDRAW",
  CHARGE = "CHARGE",
  REFUND = "REFUND",
  CORRECTION = "CORRECTION",
  BONUS_AWARDED = "BONUS_AWARDED",
}

export function GetTransactionTypeWithId(id: number) {
  switch (id) {
    case 1:
      return TransactionTypes.SPIN;
    case 2:
      return TransactionTypes.DEPOSIT;
    case 3:
      return TransactionTypes.WITHDRAW;
    case 4:
      return TransactionTypes.CHARGE;
    case 5:
      return TransactionTypes.REFUND;
    case 6:
      return TransactionTypes.CORRECTION;
    case 7:
      return TransactionTypes.BONUS_AWARDED;
    default:
      break;
  }
}

export class TransactionType {
  id: number;

  name: string;

  description: string;

  createdAt: Date;

  updatedAt: Date;

  deletedAt: Date;

  transactions?: Transaction[];
}

import React, { FunctionComponent } from "react";
import { Table } from "react-bootstrap";
import { Category } from "src/models/app/Category";
import TableRow from "./TableRow";
import { BonusGame } from "src/models/app/BonusGame";

interface IGamesTable {
  games: BonusGame[];
  categories: Category[];
  filterValues: any;
  paginationShowAmount: any;
  paginationIndex: any;
  setPaginationTotalPages: any;
  setGames: any;
}

const BonusGamesTable: FunctionComponent<IGamesTable> = (
  props: IGamesTable,
) => {
  return (
    <Table hover className="bg-white">
      <thead>
        <tr className="text-center">
          <th>#</th>
          <th>Name</th>
          <th>Bonus</th>
          <th>Wagering %</th>
        </tr>
      </thead>
      <tbody>
        {props.games.map((game: BonusGame, index) => {
          return game ? (
            <TableRow
              key={`${game.gameId}-${game.bonusTypeId}`}
              game={game}
              index={index}
              categories={props.categories}
              paginationIndex={props.paginationIndex}
              games={props.games}
            />
          ) : (
            <></>
          );
        })}
      </tbody>
    </Table>
  );
};

export default BonusGamesTable;

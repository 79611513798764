import React, { FunctionComponent } from "react";
import { Table } from "react-bootstrap";
import { PaymentMethodPaymentAccount } from "src/models/app/PaymentMethodPaymentAccount";

interface IPaymentMethodsProps {
  paymentMethodPaymentAccounts: PaymentMethodPaymentAccount[];
}

export const PaymentMethods: FunctionComponent<IPaymentMethodsProps> = (
  props: IPaymentMethodsProps,
) => {
  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>#</th>
          <th>Name</th>
          <th>Active</th>
          <th>Type</th>
          <th>MAX Deposit</th>
          <th>MAX Withdraw</th>
          <th>MIN Deposit</th>
          <th>MIN Withdraw</th>
          <th>Payment Acquirer</th>
          <th>Payment Provider</th>
        </tr>
      </thead>
      {props.paymentMethodPaymentAccounts?.map((data, key) => {
        return (
          <tr key={key}>
            <td>{data.paymentMethodId}</td>
            <td>{data.paymentMethod?.name ?? ""}</td>
            <td>{data.paymentMethod?.active.toString() ?? ""}</td>
            <td>{data.paymentMethod?.type?.code ?? ""}</td>
            <td>{data.paymentMethod?.maxDeposit ?? ""}</td>
            <td>{data.paymentMethod?.maxWithdraw ?? ""}</td>
            <td>{data.paymentMethod?.minDeposit ?? ""}</td>
            <td>{data.paymentMethod?.minWithdraw ?? ""}</td>
            <td>{data.paymentMethod?.paymentAcquirer?.name ?? ""}</td>
            <td>
              {data.paymentMethod?.paymentAcquirer?.paymentProvider?.name ?? ""}
            </td>
          </tr>
        );
      })}
    </Table>
  );
};

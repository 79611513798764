/* eslint-disable react-hooks/exhaustive-deps */
import React, { FunctionComponent, useEffect, useState } from "react";
import {
  Accordion,
  Button,
  Card,
  Form,
  // Pagination,
  Container,
  Col,
} from "react-bootstrap";
import { Provider } from "src/models/app/Provider";
import { makeRequest } from "src/utilities/axio.helper";
import FreeSpinsTable from "./FreeSpinsTable";
import config from "src/config";
import urls from "src/utilities/urls";
import { Account } from "src/models/app/Account";
import { FreeBet } from "src/models/app/FreeBet";

interface IGameListProps {
  deleteGame: (gameId: number) => Promise<void>;
}

export async function refreshPromo(
  provider: Provider,
  userId: number,
  setFreebts: any,
) {
  const { result } = await makeRequest(
    "post",
    urls.NOLIMIT_GET_FREE_SPINS,
    {
      userId,
    },
    {
      username: config.API_AUTH.USERNAME,
      password: config.API_AUTH.PASSWORD,
    },
  );

  setFreebts(result?.freeBets);
}

const FreeSpinsList: FunctionComponent<IGameListProps> = () => {
  const [paginationIndex, setPaginationIndex] = useState<number>(1);
  // const [paginationTotalPages, setPaginationTotalPages] = useState<number>(0);
  // const [paginationShowAmount] = useState<number>(8);
  const [providers, setProviders] = useState<Provider[]>([]);
  const [provider, setProvider] = useState(new Provider());
  const [users, setUsers] = useState<Account[]>([]);
  const [userId, setUserId] = useState(0);
  const [freeBets, setFreebts] = useState<FreeBet[]>([]);
  useEffect(() => {
    const getProviders = async (): Promise<void> => {
      try {
        const responseProviders = await makeRequest(
          "post",
          urls.PROVIDERS,
          {},
          {
            username: config.API_AUTH.USERNAME,
            password: config.API_AUTH.PASSWORD,
          },
        );
        const validProviders = responseProviders.filter(
          (p: Provider) => p.id === 1, // Get just No Limit for now
        );
        setProviders(validProviders);
        setProvider(validProviders[0]);
        const accountsResponse = await makeRequest(
          "post",
          urls.ACCOUNTS_LIST,
          { where: { withDeleted: false, active: true } },
          {
            username: config.API_AUTH.USERNAME,
            password: config.API_AUTH.PASSWORD,
          },
        );
        setUsers(accountsResponse.accounts);
        setUserId(accountsResponse.accounts[0]?.id);
      } catch (err) {
        console.log(err);
      }
    };
    getProviders();
  }, []);

  useEffect(() => {
    refreshPromo(provider, userId, setFreebts);
  }, []);

  useEffect(() => {
    setPaginationIndex(1);
    refreshPromo(provider, userId, setFreebts);
  }, [provider, userId]);

  useEffect(() => {
    refreshPromo(provider, userId, setFreebts);
  }, [paginationIndex]);

  // const getPagination = (): React.ReactChild => {
  //   const items = [];
  //   const filteredItems = [];
  //   let prevIndex = 0;
  //   let nextIndex = 10;

  //   for (let number = 1; number <= paginationTotalPages; number++) {
  //     items.push(
  //       <Pagination.Item
  //         key={number}
  //         active={number === paginationIndex}
  //         onClick={(): void => setPaginationIndex(number)}
  //       >
  //         {number}
  //       </Pagination.Item>,
  //     );
  //   }

  //   if (paginationIndex - 10 > 0) {
  //     prevIndex = paginationIndex - 10;
  //   }

  //   const triggeringIndex = Math.ceil(nextIndex / 2) + 1;

  //   if (paginationIndex > triggeringIndex) {
  //     nextIndex = paginationIndex + 4;
  //     prevIndex = paginationIndex - 6;
  //   }

  //   for (let index = prevIndex; index <= nextIndex; index++) {
  //     filteredItems.push(items[index]);
  //   }

  //   return (
  //     <Pagination className="m-4 flex-wrap">
  //       <Pagination.First onClick={(): void => setPaginationIndex(1)} />
  //       <Pagination.Prev
  //         onClick={(): void =>
  //           setPaginationIndex(
  //             paginationIndex !== 1 ? paginationIndex - 1 : paginationIndex,
  //           )
  //         }
  //       />
  //       {filteredItems}
  //       <Pagination.Next
  //         onClick={(): void =>
  //           setPaginationIndex(
  //             paginationIndex !== items.length
  //               ? paginationIndex + 1
  //               : paginationIndex,
  //           )
  //         }
  //       />
  //       <Pagination.Last
  //         onClick={(): void => {
  //           setPaginationIndex(items.length);
  //           getPagination();
  //         }}
  //       />
  //     </Pagination>
  //   );
  // };

  return (
    <>
      <Card className="m-4">
        <Card.Header>
          <Accordion.Toggle
            as={Button}
            variant="link"
            eventKey="0"
            onClick={() => refreshPromo(provider, userId, setFreebts)}
          >
            Filter
          </Accordion.Toggle>
        </Card.Header>
        <Card.Body>
          <Form
            onSubmit={(e: React.FormEvent<HTMLElement>): void =>
              e.preventDefault()
            }
          >
            <Form.Group as={Col} controlId="formProvider">
              <Form.Label>Provider</Form.Label>
              <Form.Control
                as="select"
                custom
                onChange={(evt: React.ChangeEvent<HTMLInputElement>): void => {
                  if (evt.target) {
                    const providerId = Number(evt.target.value);
                    const newProvider: Provider | undefined =
                      providers.find((p) => p.id === providerId) ||
                      new Provider();
                    setProvider(newProvider);
                  }
                }}
              >
                {providers?.map((value: Provider) => (
                  <option key={value.id} value={value.id}>
                    {value.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group as={Col} controlId="formProvider">
              <Form.Label>User ID</Form.Label>
              <Form.Control
                as="select"
                custom
                onChange={(evt: React.ChangeEvent<HTMLInputElement>): void => {
                  if (evt.target) {
                    const newUserId = Number(evt.target.value);
                    setUserId(newUserId);
                  }
                }}
              >
                {users?.map((value: Account) => (
                  <option key={value.id} value={value.id}>
                    {value.id}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Form>
        </Card.Body>
      </Card>

      {/* {getPagination()} */}
      <Container className="px-4 mw-none w-100">
        <FreeSpinsTable
          freeBets={freeBets}
          refreshData={() => refreshPromo(provider, userId, setFreebts)}
        />
      </Container>
    </>
  );
};

export default FreeSpinsList;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { FunctionComponent, useState } from "react";
import {
  Table,
  Card,
  Button,
  Spinner,
  Alert,
  Container,
  Row,
} from "react-bootstrap";

import { Account } from "src/models/app/Account";
import { AccountTag } from "src/models/app/AccountTag";

import { AccountTagsItem } from "./AccountTagsItem";
import { makeRequest } from "src/utilities/axio.helper";
import urls from "src/utilities/urls";

interface IAccountAccountTagsProps {
  account: Account;
}

interface RofusGamblerCheckResponse {
  gamblerExclusionStatus: string;
}

export const AccountTags: FunctionComponent<IAccountAccountTagsProps> = (
  props: IAccountAccountTagsProps,
) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [gamblerStatus, setGamblerStatus] =
    useState<RofusGamblerCheckResponse>();

  const handleSearchRofusStatus = async () => {
    if (loading) {
      toastr.warning("Please wait..");
      return;
    }

    if (!props.account.cprNumber) {
      toastr.warning("Invalid cprNumber, please check.");
      return;
    }

    try {
      setLoading(true);

      const response = await makeRequest(
        "post",
        urls.ROFUS_GAMBLER_EXCLUSION_STATUS,
        {
          cprNumber: props.account.cprNumber,
        },
      );

      setGamblerStatus(response as RofusGamblerCheckResponse);

      toastr.success("Rofus Status checked!");
    } catch (error) {
      console.log(error);
      toastr.error((error as any).message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card>
      <Card.Header>Tags </Card.Header>
      <Card.Body>
        <Container fluid className="mb-3">
          <Row>
            <Button className="mr-3 w-40" onClick={handleSearchRofusStatus}>
              {loading ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                "Check Rofus Blocked Duration"
              )}
            </Button>
            {gamblerStatus && (
              <Alert className="mb-0" variant="secondary">
                <span style={{ fontWeight: 700 }}>Result:</span>{" "}
                {gamblerStatus.gamblerExclusionStatus}
              </Alert>
            )}
          </Row>
        </Container>
        <Table striped bordered hover size="sm">
          <thead>
            <tr>
              <th>Tag</th>
              <th>First record</th>
              <th>Modified</th>
              <th>Active</th>
            </tr>
          </thead>
          <tbody>
            {props.account.accountTags.map((accountTag: AccountTag) => {
              return (
                <AccountTagsItem
                  key={accountTag.tagId}
                  {...accountTag}
                ></AccountTagsItem>
              );
            })}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { FunctionComponent, useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { Exclusion } from "src/models/app/Exclusion";
import { ExclusionTypes } from "src/models/app/ExclusionType";
import { makeRequest } from "src/utilities/axio.helper";
import { Account } from "src/models/app/Account";
import urls from "src/utilities/urls";
import { Comment } from "src/models/app/Comment";
import { RequestUpdateCommentDto } from "src/models/dto/comment.dto";
import { RequestCreateExclusionDto } from "src/models/dto/exclusion.dto";

export interface IAccountExclusionCreateModal {
  account: Account;
  removeModal: () => void;
  refreshData: () => void;
}

export const CreateAccountExclusionModal: FunctionComponent<
  IAccountExclusionCreateModal
> = (props: IAccountExclusionCreateModal) => {
  const [commentInput, setCommentInput] = useState<string>("");
  const [typeSelected, setTypeSelected] = useState<number>(1);
  const commentHeader = "Exclusion: ";

  const onHide = (): void => props.removeModal();
  const onRefresh = (): void => props.refreshData();

  const onSave = async (): Promise<void> => {
    try {
      const res = await makeRequest<Exclusion, RequestCreateExclusionDto>(
        "post",
        urls.EXCLUSIONS_CREATE,
        {
          accountId: props.account.id,
          typeId: typeSelected,
        },
      );

      if (commentInput !== "") {
        // Create Comment
        await makeRequest<Comment, RequestUpdateCommentDto>(
          "post",
          urls.COMMENTS,
          {
            comment: commentHeader + commentInput,
            accountId: props.account.id,
          },
        );
      }
      toastr.success("Created exclusion!");
      onHide();
      onRefresh();
    } catch (err) {
      toastr.error((err as any).message);
    }
  };

  const renderModal = (): React.ReactChild => {
    return (
      <Modal show={true} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Create Exclusion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="type">
              <Form.Label>Type</Form.Label>
              <Form.Control
                as="select"
                custom
                value={typeSelected}
                onChange={(evt: React.ChangeEvent<HTMLInputElement>): void => {
                  setTypeSelected(parseInt(evt.target.value));
                }}
              >
                <option value={ExclusionTypes.DAY}>DAY</option>
                <option value={ExclusionTypes.MONTH}>1 MONTH</option>
                <option value={ExclusionTypes.THREE_MONTHS}>3 MONTHS</option>
                <option value={ExclusionTypes.SIX_MONTHS}>6 MONTHS</option>
                <option value={ExclusionTypes.PERMANENT}>PERMANENT</option>
              </Form.Control>
              <Form.Group controlId="comment" className="mt-2">
                <Form.Label>Comment</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                    setCommentInput(e.target.value);
                  }}
                />
              </Form.Group>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={onSave}>
            Create
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  return <>{renderModal()}</>;
};

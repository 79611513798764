import React, { FunctionComponent, useEffect, useState } from "react";
import { Card, Table } from "react-bootstrap";
import { useParams } from "react-router-dom";
import urls from "../../../utilities/urls";
import { Game } from "../../../models/app/Game";
import { makeRequest } from "../../../utilities/axio.helper";
import {
  GetGamesWhereDto,
  RequestGetGamesDto,
  ResponseGetGamesDto,
} from "src/models/dto/game.dto";

import CategoryGamesRow from "./CategoryGamesRow";
import {
  RequestGetCategoryDto,
  ResponseGetCategoryDto,
} from "src/models/dto/category.dto";
import { Category } from "src/models/app/Category";

const CategoryGamesList: FunctionComponent = () => {
  const { categoryId } = useParams<{ categoryId: string }>();
  const categoryIdInt = categoryId ? parseInt(categoryId) : 0;
  const [category, setCategory] = useState<Category>();
  const [games, setGames] = useState<Game[] | undefined>(undefined);

  function sortByWeight(a: Game, b: Game) {
    let gameA = a.gameCategories[0].weight;
    let gameB = b.gameCategories[0].weight;

    if (!gameA) gameA = 0;
    if (!gameB) gameB = 0;

    let comparison = 0;
    if (gameA > gameB) {
      comparison = -1;
    } else if (gameA < gameB) {
      comparison = 1;
    } else {
      comparison = 0;
    }
    return comparison;
  }

  useEffect(() => {
    const getData = async (): Promise<void> => {
      if (categoryIdInt > 0 && games === undefined) {
        try {
          const where: GetGamesWhereDto = new GetGamesWhereDto();
          where.categories = [categoryIdInt];
          const getGamesResponse: ResponseGetGamesDto = await makeRequest<
            ResponseGetGamesDto,
            RequestGetGamesDto
          >("post", urls.GAMES_LIST, {
            where,
          });
          setGames(getGamesResponse.games.sort(sortByWeight));

          const getCurrentCategory: ResponseGetCategoryDto = await makeRequest<
            ResponseGetCategoryDto,
            RequestGetCategoryDto
          >("post", urls.CATEGORIES_LIST, {
            where: {
              id: [categoryIdInt],
            },
          });
          setCategory(getCurrentCategory.categories[0]);
        } catch (err) {
          toastr.error(err.message);
        }
      }
    };

    getData();
  }, [categoryIdInt, games]);

  return (
    <div>
      <Card>
        <Card.Header>
          <b>{category?.name ?? ""}</b> Game Order By Weight
        </Card.Header>
        <Card.Body>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Weight</th>
                <th>Move</th>
                <th>Remove</th>
              </tr>
            </thead>
            <tbody>
              {games?.map((game: Game, key: number) => (
                <CategoryGamesRow
                  game={game}
                  setGames={setGames}
                  key={key}
                  category={category}
                  index={key}
                  topWeight={games[0].gameCategories[0].weight}
                />
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </div>
  );
};

export default CategoryGamesList;

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import DayJS from "dayjs";
import React, { FunctionComponent, useState, useEffect } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { DepositLimit } from "src/models/app/DepositLimit";
import { makeRequest } from "src/utilities/axio.helper";
import { Account } from "src/models/app/Account";
import urls from "src/utilities/urls";
import { Language } from "src/models/app/Languages";
import { GetLanguagesDto } from "src/models/dto/language.dto";
import { Currency } from "src/models/app/Currency";
import { GetCurrenciesDto } from "src/models/dto/currency.dto";
import { Country } from "src/models/app/Country";
import { GetCountriesDto } from "src/models/dto/country.dto";
import { RequestUpsertAccountDto } from "src/models/dto/account.dto";

export interface ITestAccountCreateModal {
  removeModal: () => void;
  onSave: (account: Account) => void;
}

export const TestAccountCreateModal: FunctionComponent<
  ITestAccountCreateModal
> = (props: ITestAccountCreateModal) => {
  const [account, setAccount] = useState<Account>(new Account());

  const onHide = (): void => props.removeModal();

  useEffect(() => {
    const setupTestAccount = async (): Promise<void> => {
      const languages: Language[] = await makeRequest<
        Language[],
        GetLanguagesDto
      >("post", urls.LANGUAGES_LIST);

      const currencies: Currency[] = await makeRequest<
        Currency[],
        GetCurrenciesDto
      >("post", urls.CURRENCIES_LIST);

      const countries: Country[] = await makeRequest<
        Country[],
        GetCountriesDto
      >("post", urls.COUNTRIES_LIST);

      const testAccount = new Account();
      testAccount.city = "Test City";
      testAccount.language = languages[0];
      testAccount.country = countries[0];
      testAccount.active = true;
      testAccount.emailMarketing = true;
      testAccount.isTest = 1;
      testAccount.dateOfBirth = new Date(2000, 8, 8);
      testAccount.zipcode = "Test Zip";
      setAccount(testAccount);
    };
    setupTestAccount();
  }, []);

  const onCreate = async (): Promise<void> => {
    try {
      // Create Account
      await makeRequest<DepositLimit, RequestUpsertAccountDto>(
        "post",
        urls.ACCOUNTS_CREATE_TEST,
        {
          ...account,
          dateOfBirth: DayJS(account.dateOfBirth).format("YYYY-MM-DD"),
        },
      );

      toastr.success("Created Account!");

      props.onSave(account);
      onHide();
    } catch (err) {
      toastr.error(err.message);
    }
  };

  const renderModal = (): React.ReactChild => {
    return (
      <Modal show={true} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Create Limit</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="email">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                required
                type="email"
                placeholder="Enter name"
                onChange={(e): void =>
                  setAccount({ ...account, email: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group controlId="docNum">
              <Form.Label>Document Number</Form.Label>
              <Form.Control
                required
                type="test"
                placeholder="Enter document number"
                onChange={(e): void =>
                  setAccount({ ...account, cprNumber: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group controlId="pidNum">
              <Form.Label>Pid Number</Form.Label>
              <Form.Control
                required
                type="test"
                placeholder="Enter pid number"
                onChange={(e): void =>
                  setAccount({ ...account, pid: e.target.value })
                }
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={onCreate}>
            Create
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  return <>{renderModal()}</>;
};

/* eslint-disable react-hooks/exhaustive-deps */
import React, { FunctionComponent, useEffect, useState } from "react";
import {
  Accordion,
  Button,
  Card,
  Form,
  Pagination,
  Container,
} from "react-bootstrap";
import { makeRequest } from "src/utilities/axio.helper";
import {
  GetCommentsWhereDto,
  RequestGetCommentDto,
  ResponseGetComments,
} from "src/models/dto/comment.dto";
import urls from "src/utilities/urls";
import CommentsTable from "./CommentsTable";
import DayJS from "dayjs";
import DateInput from "src/components/inputs/DateInput";
import { CommentAction, CommentType } from "src/models/app/Comment";
import Multiselect from "react-multi-select-component";
import { Option } from "react-multi-select-component/dist/lib/interfaces";

class IFilterValues {
  id: number[] = [];
  accountNames: string[] = [];
  accountId: number[] = [];
  backOfficeAccountId: number[] = [];
  backOfficeAccountNames: string[] = [];
  types: string[] = [];
  actions: string[] = [];
}

const CommentsList: FunctionComponent = () => {
  const [paginationIndex, setPaginationIndex] = useState<number>(1);
  const [paginationTotalPages, setPaginationTotalPages] = useState<number>(0);
  const [paginationShowAmount] = useState<number>(8);
  const [comments, setComments] = useState<ResponseGetComments>();
  const [filterValues, setFilterValues] = useState<IFilterValues>(
    new IFilterValues(),
  );

  const [fromDate, setFromDate] = useState(
    DayJS(new Date()).format("YYYY-MM-DD"),
  );
  const [toDate, setToDate] = useState(DayJS(new Date()).format("YYYY-MM-DD"));
  const [applyDateFilter, setApplyDateFilter] = useState<boolean>(false);
  const [clearDateFilter, setClearDateFilter] = useState<boolean>(false);

  const getActionsFilter = (): React.ReactElement => {
    return (
      <Form.Group controlId="actions">
        <Form.Label>Actions</Form.Label>
        <Multiselect
          value={filterValues.actions.map((action: string): Option => {
            return { value: action, label: action };
          })}
          options={Object.values(CommentAction).map(
            (action: string): Option => {
              return { label: action, value: action };
            },
          )}
          onChange={(options: Option[]): void => {
            const selectedActions = options.map(
              (option): string => option.value,
            );
            setFilterValues({
              ...filterValues,
              actions: selectedActions,
            });
          }}
          labelledBy={"actions"}
        />
      </Form.Group>
    );
  };

  const getTypesFilter = (): React.ReactElement => {
    return (
      <Form.Group controlId="types">
        <Form.Label>Types</Form.Label>
        <Multiselect
          value={filterValues.types.map((type: string): Option => {
            return { value: type, label: type };
          })}
          options={Object.values(CommentType).map((type: string): Option => {
            return { label: type, value: type };
          })}
          onChange={(options: Option[]): void => {
            const selectedType = options.map((option): string => option.value);
            setFilterValues({
              ...filterValues,
              types: selectedType,
            });
          }}
          labelledBy={"types"}
        />
      </Form.Group>
    );
  };

  const refreshComments = async (): Promise<void> => {
    try {
      const dateFilters = {
        id: filterValues.id,
        accountNames: filterValues.accountNames,
        accountId: filterValues.accountId,
        backOfficeAccountId: filterValues.backOfficeAccountId,
        backOfficeAccountNames: filterValues.backOfficeAccountNames,
        types: filterValues.types,
        actions: filterValues.actions,
        startDate: DayJS(fromDate).format("YYYY-MM-DD"),
        endDate: DayJS(toDate).format("YYYY-MM-DD"),
      };

      const normalFilters = {
        id: filterValues.id,
        accountNames: filterValues.accountNames,
        accountId: filterValues.accountId,
        backOfficeAccountId: filterValues.backOfficeAccountId,
        backOfficeAccountNames: filterValues.backOfficeAccountNames,
        types: filterValues.types,
        actions: filterValues.actions,
      };

      const where: GetCommentsWhereDto = applyDateFilter
        ? dateFilters
        : normalFilters;

      if (filterValues.id.length > 0) {
        where.id = filterValues.id;
      }

      if (filterValues.accountId.length > 0) {
        where.accountId = filterValues.accountId;
      }

      if (filterValues.accountNames.length > 0) {
        where.accountNames = filterValues.accountNames;
      }

      if (filterValues.backOfficeAccountId.length > 0) {
        where.backOfficeAccountId = filterValues.backOfficeAccountId;
      }

      if (filterValues.backOfficeAccountNames.length > 0) {
        where.backOfficeAccountNames = filterValues.backOfficeAccountNames;
      }

      if (filterValues.actions.length > 0) {
        where.actions = filterValues.actions;
      }

      if (filterValues.types.length > 0) {
        where.types = filterValues.types;
      }

      const commentsResponse: ResponseGetComments = await makeRequest<
        ResponseGetComments,
        RequestGetCommentDto
      >("post", urls.COMMENTS_LIST, {
        where,
        offset: paginationShowAmount * (paginationIndex - 1),
        limit: paginationShowAmount,
        withBackOfficeAccount: true,
      });

      setComments(commentsResponse);

      setPaginationTotalPages(
        Math.ceil(commentsResponse.totalComments / paginationShowAmount),
      );
    } catch (err) {
      toastr.error((err as any).message);
    }
  };

  useEffect(() => {
    refreshComments();
  }, []);

  useEffect(() => {
    if (applyDateFilter) {
      setPaginationIndex(1);
      refreshComments();
    }
  }, [applyDateFilter]);

  useEffect(() => {
    if (clearDateFilter) {
      refreshComments();
    }
  }, [clearDateFilter]);

  useEffect(() => {
    setPaginationIndex(1);
    refreshComments();
  }, [filterValues]);

  useEffect(() => {
    refreshComments();
  }, [paginationIndex]);

  const getPagination = (): React.ReactChild => {
    const items = [];
    const filteredItems = [];
    let prevIndex = 0;
    let nextIndex = 10;

    for (let number = 1; number <= paginationTotalPages; number++) {
      items.push(
        <Pagination.Item
          key={number}
          active={number === paginationIndex}
          onClick={(): void => {
            setPaginationIndex(number);
          }}
        >
          {number}
        </Pagination.Item>,
      );
    }

    if (paginationIndex - 10 > 0) {
      prevIndex = paginationIndex - 10;
    }

    const triggeringIndex = Math.ceil(nextIndex / 2) + 1;

    if (paginationIndex > triggeringIndex) {
      nextIndex = paginationIndex + 4;
      prevIndex = paginationIndex - 6;
    }

    for (let index = prevIndex; index <= nextIndex; index++) {
      filteredItems.push(items[index]);
    }

    return (
      <Pagination className="m-4 flex-wrap">
        <Pagination.First onClick={(): void => setPaginationIndex(1)} />
        <Pagination.Prev
          onClick={(): void =>
            setPaginationIndex(
              paginationIndex !== 1 ? paginationIndex - 1 : paginationIndex,
            )
          }
        />
        {filteredItems}
        <Pagination.Next
          onClick={(): void =>
            setPaginationIndex(
              paginationIndex !== items.length
                ? paginationIndex + 1
                : paginationIndex,
            )
          }
        />
        <Pagination.Last
          onClick={(): void => {
            setPaginationIndex(items.length);
            getPagination();
          }}
        />
      </Pagination>
    );
  };

  const commentsView = () => {
    return (
      <>
        <Accordion defaultActiveKey="0">
          <Card className="m-4" style={{ overflow: "unset" }}>
            <Card.Header>
              <Accordion.Toggle as={Button} variant="link" eventKey="0">
                Filter
              </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <Form
                  onSubmit={(e: React.FormEvent<HTMLElement>): void =>
                    e.preventDefault()
                  }
                >
                  <>
                    <Form.Group controlId="id">
                      <Form.Label>Id Filter</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Example: 12, 15"
                        onChange={(
                          evt: React.ChangeEvent<HTMLInputElement>,
                        ): void => {
                          let id = undefined;
                          if (evt.target.value) {
                            id = evt.target.value;
                          }

                          let ids: number[] = [];
                          if (id) {
                            ids = id?.split(",").map((id) => Number(id));
                          }

                          setFilterValues({
                            ...filterValues,
                            id: ids,
                          });
                        }}
                      />
                    </Form.Group>
                    <Form.Group controlId="Account Name">
                      <Form.Label>Account Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Example: Jack, Jill, Up, The,Hill"
                        onChange={(
                          evt: React.ChangeEvent<HTMLInputElement>,
                        ): void => {
                          let accName = undefined;
                          if (evt.target.value) {
                            accName = evt.target.value;
                          }

                          let accNames: string[] = [];
                          if (accName) {
                            accNames = accName
                              .split(",")
                              .map((accName) => accName);
                          }

                          setFilterValues({
                            ...filterValues,
                            accountNames: accNames,
                          });
                        }}
                      />
                    </Form.Group>
                    <Form.Group controlId="Account Id">
                      <Form.Label>Account ID</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Example: 12, 15"
                        onChange={(
                          evt: React.ChangeEvent<HTMLInputElement>,
                        ): void => {
                          let id = undefined;
                          if (evt.target.value) {
                            id = evt.target.value;
                          }

                          let ids: number[] = [];
                          if (id) {
                            ids = id?.split(",").map((id) => Number(id));
                          }

                          setFilterValues({
                            ...filterValues,
                            accountId: ids,
                          });
                        }}
                      />
                    </Form.Group>
                    <Form.Group controlId="Backoffice Account Id">
                      <Form.Label>Backoffice Account ID</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Example: 12, 15"
                        onChange={(
                          evt: React.ChangeEvent<HTMLInputElement>,
                        ): void => {
                          let id = undefined;
                          if (evt.target.value) {
                            id = evt.target.value;
                          }

                          let ids: number[] = [];
                          if (id) {
                            ids = id?.split(",").map((id) => Number(id));
                          }

                          setFilterValues({
                            ...filterValues,
                            backOfficeAccountId: ids,
                          });
                        }}
                      />
                    </Form.Group>
                    <>
                      {getTypesFilter()}
                      {getActionsFilter()}
                    </>
                    <DateInput
                      label="Date Range (Created At)"
                      setFromDate={setFromDate}
                      setToDate={setToDate}
                      setApplyDateFilter={setApplyDateFilter}
                      setClearDateFilter={setClearDateFilter}
                    />
                  </>
                </Form>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
        {getPagination()}
        <Container className="px-4 mw-none w-100">
          <CommentsTable comments={comments!} />
        </Container>
      </>
    );
  };

  return <>{commentsView()}</>;
};

export default CommentsList;

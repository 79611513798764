/**
    //////////////////////////////////////////////////////////////////////
    THIS FILE WAS GENEREATED
    Run 'npm run export-models' on backend to sync models
    ((DO NOT MODIFIY!!!))
    //////////////////////////////////////////////////////////////////////
    */

/* -------------------------------- Models -------------------------------- */
import { GameTheme } from "./GameTheme";
import { Game } from "./Game";

export class Theme {
  id: number;

  name: string;

  code: string;

  active: number;

  createdAt: Date;

  updatedAt: Date;

  deletedAt: Date;

  games: Game[];

  gameThemes: GameTheme[];
}

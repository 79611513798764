import React, { FunctionComponent } from "react";
import { Account } from "src/models/app/Account";
import { DepositLimit } from "src/models/app/DepositLimit";
import DayJS from "dayjs";

export interface IAccountDepositLimitItemProps {
  account: Account;
  depositLimit: DepositLimit;
  onUpdated: (depositLimit: DepositLimit) => void;
}

export const AccountDepositLimitItem: FunctionComponent<
  IAccountDepositLimitItemProps
> = (props: IAccountDepositLimitItemProps) => {
  const depositType = (type: number) => {
    enum DepositLimitTypes {
      DAILY = 1,
      WEEKLY = 2,
      MONTHLY = 3,
    }

    return type === DepositLimitTypes.DAILY
      ? "Daily"
      : type === DepositLimitTypes.WEEKLY
      ? "Weekly"
      : "Monthly";
  };

  return (
    <>
      <tr key={props.depositLimit.typeId}>
        <td>{depositType(props.depositLimit.typeId)}</td>
        <td>{props.depositLimit.amount}</td>
        <td>{props.depositLimit.remaining}</td>
        <td>
          {DayJS(props.depositLimit.lastReset).format("YYYY-MM-DD HH:mm:ss")}
        </td>
        <td>
          {DayJS(props.depositLimit.nextReset).format("YYYY-MM-DD HH:mm:ss")}
        </td>
        <td>{props.depositLimit.increase}</td>
        <td>
          {props.depositLimit.nextIncrease &&
            DayJS(props.depositLimit.nextIncrease).format(
              "YYYY-MM-DD HH:mm:ss",
            )}
        </td>
      </tr>
    </>
  );
};

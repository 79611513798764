import React, { FunctionComponent, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, ButtonGroup, Col, Form } from "react-bootstrap";
import DayJS from "dayjs";
import { Provider } from "src/models/app/Provider";

import { makeRequest } from "src/utilities/axio.helper";
import urls from "src/utilities/urls";

export interface IProviderListItem {
  provider: Provider;
}

function ReactSwitch(provider: Provider, checked: boolean, setChecked: any) {
  const saveActive = async (provider: Provider): Promise<void> => {
    if (provider.active === 1) {
      provider.active = 0;
      setChecked(false);
      saveProvider(provider);
    } else {
      provider.active = 1;
      setChecked(true);

      saveProvider(provider);
    }
  };

  return (
    <>
      <Col>
        <Form.Group>
          <Form.Check
            label=""
            type="switch"
            id={provider.id.toString()}
            checked={checked}
            onChange={() => saveActive(provider)}
          />
        </Form.Group>
      </Col>
    </>
  );
}

async function saveProvider(provider: Provider) {
  if (provider.id) {
    try {
      await makeRequest<Provider>("post", urls.PROVIDERS_UPDATE, {
        providerId: provider.id,
        active: provider.active,
      });
      toastr.success("Provider Updated!");
    } catch (err) {
      toastr.error(err);
    }
  }
}

export const ProviderListItem: FunctionComponent<IProviderListItem> = (
  props: IProviderListItem,
) => {
  const formatDate = (date?: Date): string => {
    if (date === null) {
      return "";
    }
    return DayJS(date).format("YYYY-MM-DD HH:mm:ss");
  };

  const [checked, setChecked] = useState(props.provider.active === 1);
  const history = useHistory();

  return (
    <>
      <tr key={props.provider.id}>
        <td>{props.provider.id}</td>
        <td>{props.provider.name}</td>
        <td>{formatDate(props.provider.createdAt)}</td>
        <td>{formatDate(props.provider.updatedAt)}</td>
        <td>{ReactSwitch(props.provider, checked, setChecked)}</td>
        <td>
          <ButtonGroup
            className="float-sm-left mr-1"
            aria-label="Basic example"
          >
            <Button
              variant="secondary"
              onClick={() =>
                history.push(`/providers/gamelist/${props.provider.id}`)
              }
            >
              Games
            </Button>
          </ButtonGroup>
        </td>
      </tr>
    </>
  );
};

/**
    //////////////////////////////////////////////////////////////////////
    THIS FILE WAS GENEREATED
    Run 'npm run export-models' on backend to sync models
    ((DO NOT MODIFIY!!!))
    //////////////////////////////////////////////////////////////////////
    */

// Models

export enum ExclusionTypes {
  DAY = 1,
  MONTH = 2,
  PERMANENT = 3,
  THREE_MONTHS = 4,
  SIX_MONTHS = 5,
}

export class ExclusionType {
  id: number;
}

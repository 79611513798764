import React, {
  FunctionComponent,
  useState,
  Dispatch,
  SetStateAction,
} from "react";
import { Game } from "../../../models/app/Game";
import { Button, CloseButton, Form } from "react-bootstrap";
import urls from "src/utilities/urls";
import { RequestUpdateGameCategoryDto } from "src/models/dto/game.category.dto";
import { makeRequest } from "src/utilities/axio.helper";
import { GameCategory } from "src/models/app/GameCategory";
import { RequestGetGamesDto, UpdateGameDto } from "src/models/dto/game.dto";
import { Category } from "src/models/app/Category";

const NEW_GAME_CATEGORY_ID: number = 3;
const MAX_NEW_GAMES_COUNT: number = 24;

interface ICategoryGamesRowComponent {
  game: Game;
  category?: Category;
  setGames: Dispatch<SetStateAction<Game[] | undefined>>;
  index: number;
  topWeight?: number;
}

const CategoryGamesRow: FunctionComponent<ICategoryGamesRowComponent> = (
  props: ICategoryGamesRowComponent,
) => {
  const [gameCategory, setGameCategory] = useState<GameCategory>(
    props.game.gameCategories[0],
  );

  const [initialWeight, setInitialWeight] = useState<any>(
    props.game.gameCategories[0]?.weight,
  );

  const updateGameCategory = async (
    forceWeightUpdate?: number,
  ): Promise<void> => {
    try {
      await makeRequest<GameCategory, RequestUpdateGameCategoryDto>(
        "post",
        urls.GAME_CATEGORIES,
        {
          ...gameCategory,
          weight: forceWeightUpdate ? forceWeightUpdate : gameCategory.weight,
        },
      );
      setInitialWeight(gameCategory.weight);
      // Forces reset of game list
      props.setGames(undefined);
      toastr.success("Updated Weight!");
    } catch (err) {
      toastr.error(err.message);
    }
  };

  const changeWeight = () => {
    return (
      <Form.Control
        type="number"
        value={gameCategory.weight}
        onChange={(evt: React.ChangeEvent<HTMLInputElement>): void => {
          setGameCategory({
            ...gameCategory,
            weight: Number(evt.target.value),
          });
        }}
        onBlur={(): void => {
          if (initialWeight !== gameCategory.weight) updateGameCategory();
        }}
        onKeyDown={(evt: React.KeyboardEvent<HTMLDivElement>) => {
          if (evt.key === "Enter") {
            if (initialWeight !== gameCategory.weight) updateGameCategory();
          }
        }}
      />
    );
  };

  const removeFromCategory = async () => {
    try {
      const { games } = await makeRequest<
        { games: Game[]; totalGames: number },
        RequestGetGamesDto
      >("post", `${urls.GAMES_LIST}`, {
        where: {
          id: [props.game.id],
        },
      });

      const currentCategoryIds: number[] = games[0].gameCategories.map(
        (category) => category.categoryId,
      );

      await makeRequest<Game, UpdateGameDto>("post", `${urls.GAMES_UPDATE}`, {
        ...games[0],
        gameCategorieIds: currentCategoryIds.filter((categoryId) => {
          if (props.category) {
            return categoryId !== props.category.id;
          }
          return true;
        }),
        primaryCategoryId: games[0].primaryCategory.id,
      });

      // Updates the game list ??
      props.setGames([]);

      toastr.success(
        `[${games[0].name}] removed from [${props.category?.name}]!`,
      );
    } catch (err) {
      toastr.error(err.message);
    }
  };

  const additionnalProperties: any = {};
  if (
    props.category?.id === NEW_GAME_CATEGORY_ID &&
    props.index > MAX_NEW_GAMES_COUNT - 1
  ) {
    additionnalProperties.style = { background: "#FFE8E3" };
  }

  return (
    <tr key={props.game.id} {...additionnalProperties}>
      <td>
        {" "}
        <a href={`/games/edit/${props.game.id}`}>{props.game.id}</a>
      </td>
      <td>{props.game.name}</td>
      <td>{changeWeight()}</td>
      <td align="center">
        <Button
          disabled={props.index === 0}
          hidden={props.index === 0}
          onClick={async () => {
            if (props.topWeight) {
              await updateGameCategory(props.topWeight + 5);
            }
          }}
        >
          {"TOP"}
        </Button>
      </td>
      <td align="center">
        <CloseButton style={{ float: "none" }} onClick={removeFromCategory} />
      </td>
    </tr>
  );
};

export default CategoryGamesRow;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { FunctionComponent, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import {
  AccountStatusType,
  AccountStatusChangeReason,
} from "src/models/app/AccountStatusLog";
import {
  ResponseGetAccountStatusDto,
  RequestGetAccountStatusDto,
} from "src/models/dto/account.dto";
import { makeRequest } from "src/utilities/axio.helper";
import urls from "src/utilities/urls";
import { Account } from "src/models/app/Account";

interface IAccountStatusCheckboxProps {
  account: Account;
  type: AccountStatusType;
  active: boolean;
  textShown: string;
  onChange?: (checkboxStatus: ICheckboxStatus) => Promise<void>;
}

export interface ICheckboxStatus {
  reason?: AccountStatusChangeReason;
  active: boolean;
}

export const AccountStatusCheckbox: FunctionComponent<
  IAccountStatusCheckboxProps
> = (props: IAccountStatusCheckboxProps) => {
  const [checkboxStatus, setCheckboxStatus] = useState<ICheckboxStatus>({
    active: true,
  });

  const [initialised, setInitialised] = useState<boolean>(false);

  const getLabel = (): string => {
    let label = props.textShown;

    if (!checkboxStatus.active) {
      label += `(${checkboxStatus.reason})`;
    }

    return label;
  };

  const getLastCloseReason = async (): Promise<
    AccountStatusChangeReason | undefined
  > => {
    try {
      const response: ResponseGetAccountStatusDto = await makeRequest<
        ResponseGetAccountStatusDto,
        RequestGetAccountStatusDto
      >("post", urls.ACCOUNT_STATUS_LIST, {
        accountId: Number(props.account.id),
        cprNumber: props.account.cprNumber,
      });

      let reason: AccountStatusChangeReason | undefined = undefined;

      if (props.type === AccountStatusType.ACCOUNT && response.account) {
        reason = response.account.reason;
      }

      if (
        props.type === AccountStatusType.EMAIL_MARKETING &&
        response.emailMarketing
      ) {
        reason = response.emailMarketing.reason;
      }

      if (
        props.type === AccountStatusType.SMS_MARKETING &&
        response.smsMarketing
      ) {
        reason = response.smsMarketing.reason;
      }

      return reason;
    } catch (error) {
      toastr.error((error as any).message);
    }
  };

  useEffect(() => {
    if (checkboxStatus) {
      if (props.onChange) {
        props.onChange(checkboxStatus);
      }
    }
  }, [checkboxStatus]);

  const updateStatus = async (
    active: boolean,
    reason?: AccountStatusChangeReason,
  ): Promise<void> => {
    try {
      setCheckboxStatus({ active, reason });
    } catch (error) {
      toastr.error((error as any).message);
    }
  };

  useEffect(() => {
    const getData = async (): Promise<void> => {
      if (initialised === false) {
        let accountActive = props.account.active;
        if (props.type === AccountStatusType.EMAIL_MARKETING) {
          accountActive = props.account.emailMarketing;
        }
        if (props.type === AccountStatusType.SMS_MARKETING) {
          accountActive = props.account.smsMarketing;
        }

        if (accountActive === false) {
          const lastCloseReason = await getLastCloseReason();
          if (lastCloseReason) {
            setCheckboxStatus({
              active: false,
              reason: lastCloseReason,
            });
          }
        } else {
          setCheckboxStatus({
            active: true,
          });
        }
        setInitialised(true);
      }
    };
    setCheckboxStatus({ ...checkboxStatus, active: props.active });

    getData();
  }, [props.account, props.active]);

  return (
    <Form.Check
      type="checkbox"
      label={getLabel()}
      checked={checkboxStatus.active}
      onChange={async (
        evt: React.ChangeEvent<HTMLInputElement>,
      ): Promise<void> => {
        if (evt.target) {
          const active: boolean = evt.target.checked ? true : false;
          if (active) {
            await updateStatus(active, AccountStatusChangeReason.CLOSED);
          } else {
            await updateStatus(active);
          }
        }
      }}
    />
  );
};

/* eslint-disable react-hooks/exhaustive-deps */
import React, { FunctionComponent } from "react";
import { Table, Card } from "react-bootstrap";
import { Account } from "src/models/app/Account";
import { Wallet } from "src/models/app/Wallet";
import { AccountWalletItem } from "./AccountWalletItem";

interface IAccountCommentsProps {
  account: Account;
}

export const AccountWallets: FunctionComponent<IAccountCommentsProps> = (
  props: IAccountCommentsProps,
) => {
  return (
    <Card>
      <Card.Header>Wallets</Card.Header>
      <Card.Body>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Id</th>
              <th>Type</th>
              <th>Currency</th>
              <th>Balance</th>
            </tr>
          </thead>
          <tbody>
            {props.account.wallets?.map((wallet: Wallet) => {
              return (
                <AccountWalletItem
                  key={wallet.id}
                  {...wallet}
                ></AccountWalletItem>
              );
            })}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

import React, { FunctionComponent, useEffect, useState } from "react";
import { Card, Table } from "react-bootstrap";
import { useParams } from "react-router-dom";
import urls from "../../../utilities/urls";
import { Game } from "../../../models/app/Game";
import { makeRequest } from "../../../utilities/axio.helper";
import {
  GetGamesWhereDto,
  RequestGetGamesDto,
  ResponseGetGamesDto,
} from "src/models/dto/game.dto";

import ProviderGamesRow from "./ProviderGamesRow";

const ProviderGamesList: FunctionComponent = () => {
  const { providerId } = useParams<{ providerId: string }>();
  const providerIdInt = providerId ? parseInt(providerId) : 0;
  const [games, setGames] = useState<Game[]>([]);

  function sortByWeight(a: Game, b: Game) {
    let gameA = a.providerWeight;
    let gameB = b.providerWeight;

    if (!gameA) gameA = 0;
    if (!gameB) gameB = 0;

    let comparison = 0;
    if (gameA > gameB) {
      comparison = -1;
    } else if (gameA < gameB) {
      comparison = 1;
    } else {
      comparison = 0;
    }
    return comparison;
  }

  useEffect(() => {
    const getData = async (): Promise<void> => {
      if (providerIdInt > 0 && games.length === 0) {
        try {
          const where: GetGamesWhereDto = new GetGamesWhereDto();
          where.providers = [providerIdInt];
          where.active = 1;
          const getGamesResponse: ResponseGetGamesDto = await makeRequest<
            ResponseGetGamesDto,
            RequestGetGamesDto
          >("post", urls.GAMES_LIST, {
            where,
          });
          setGames(getGamesResponse.games.sort(sortByWeight));
        } catch (err) {
          toastr.error(err.message);
        }
      }
    };

    getData();
  }, [providerIdInt, games]);

  return (
    <div>
      <Card>
        <Card.Header>Game Order By Weight</Card.Header>
        <Card.Body>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Weight</th>
              </tr>
            </thead>
            <tbody>
              {games.map((game: Game, key: number) => (
                <ProviderGamesRow game={game} setGames={setGames} key={key} />
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </div>
  );
};

export default ProviderGamesList;

import React, { FunctionComponent } from "react";
import { Category } from "src/models/app/Category";
import { Button } from "react-bootstrap";
import { BonusGame } from "src/models/app/BonusGame";
import { useHistory } from "react-router-dom";

interface ITableRowComponent {
  game: BonusGame;
  index: number;
  categories: Category[];
  paginationIndex: any;
  games: BonusGame[];
}

const TableRow: FunctionComponent<ITableRowComponent> = (
  props: ITableRowComponent,
) => {
  const history = useHistory();
  return (
    <tr key={props.index} className={"text-center"}>
      <td>{props.game.gameId}</td>
      <td>{props.game.game.name}</td>
      <td>{props.game.bonusType.description}</td>
      <td>{props.game.wageringPercentage}%</td>

      <td>
        <Button
          className="mr-1"
          onClick={() => {
            history.push({
              pathname: `/bonus-game/edit/${props.game.gameId}`,
              search: `${props.game.bonusType.id}`,
            });
          }}
          variant="primary"
        >
          Edit
        </Button>
      </td>
    </tr>
  );
};

export default TableRow;

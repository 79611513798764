/**
    //////////////////////////////////////////////////////////////////////
    THIS FILE WAS GENEREATED
    Run 'npm run export-models' on backend to sync models
    ((DO NOT MODIFIY!!!))
    //////////////////////////////////////////////////////////////////////
    */

/* -------------------------------- Models -------------------------------- */
import { Game } from "./Game";

export enum ProviderCodes {
  NOLIMIT = "nolimit",
  BOOMING = "booming",
}

export class Provider {
  id: number;

  name: string;

  code: ProviderCodes;

  active: number;

  createdAt: Date;

  updatedAt: Date;

  deletedAt: Date;

  games: Game[];
}

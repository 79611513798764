import React, { FunctionComponent } from "react";
import { ResponseGetCommentDto } from "src/models/dto/comment.dto";
import { AccountTag } from "src/models/app/AccountTag";
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { safeFormatDate } from "src/utilities/date";
import { Tags } from "src/models/app/Tag";

export interface IAccountCommentItemProps {
  commentDto: ResponseGetCommentDto;
}

export const AccountTagsItem: FunctionComponent<AccountTag> = (
  props: AccountTag,
) => {
  const renderTooltip = (text: string) => (
    <Tooltip id="button-tooltip" {...props}>
      {props.tag.id === Tags.ROFUS_MARKETING ? "ROFUS BLOCKED" : text}
    </Tooltip>
  );

  return (
    <tr key={props.tag.id}>
      <th>
        <OverlayTrigger
          placement="right"
          overlay={renderTooltip(props.tag.description)}
        >
          <span>
            {props.tag.id === Tags.ROFUS_MARKETING
              ? "ROFUS BLOCKED"
              : props.tag.name}
          </span>
        </OverlayTrigger>
      </th>
      <th>{safeFormatDate(new Date(props.createdAt))}</th>
      <th>{safeFormatDate(new Date(props.updatedAt))}</th>
      <th className="d-flex justify-content-center ">
        <Form>
          <Form.Switch
            id={`${props.tag.id}-switch`}
            checked={!props.deletedAt}
            readOnly
          />
        </Form>
      </th>
    </tr>
  );
};

import React, { FunctionComponent, useState } from "react";
import { Card, Table, Modal, Button } from "react-bootstrap";
import { ManualWithdraw } from "./ManualWithdraw";
import { makeRequest } from "../../../utilities/axio.helper";
import urls from "../../../utilities/urls";
import { CreateManualTransactionDto } from "../../../models/dto/transaction.dto";
import toastr from "toastr";
import { Account } from "src/models/app/Account";
import { WalletTypes } from "src/models/app/WalletType";

interface IRequestDto {
  paymentMethod: string;
  amount: number;
  modifyWagering: boolean;
  comment: string;
  withdrawFee: string;
}

const ManualTransaction: FunctionComponent<{
  account: Account;
  refreshAccount: () => void;
}> = ({ account, refreshAccount }) => {
  const [transactionData, setTransactionData] = useState<any>(null);
  const [showModal, setShowModal] = useState(false);
  const walletBalance = account.wallets?.find(
    (i) => i.typeId === WalletTypes.REAL,
  )?.balance;

  const handleManualTransactionSubmit = async (data: IRequestDto) => {
    try {
      const paymentPayload: CreateManualTransactionDto = {
        paymentMethod: data.paymentMethod,
        amount: data.amount,
        removedAmountFromWagering: data.modifyWagering ? data.amount : 0,
        comment: data.comment,
        accountId: account.id,
        withdrawalFee: +data.withdrawFee,
      };

      setTransactionData(paymentPayload);
      setShowModal(true);
    } catch (e) {
      toastr.error((e as any).message);
    }
  };

  const handleSendRequest = async () => {
    try {
      await makeRequest<CreateManualTransactionDto>(
        "post",
        urls.MANUAL_TRANSACTIONS,
        transactionData,
      );
      setShowModal(false);
      toastr.success(`Manual transaction for Account ${account.name}`);
    } catch (e) {
      toastr.error((e as any).message);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <h2 className="mt-2" style={{ marginLeft: "2rem" }}>
        Manual Balance Adjustment
      </h2>
      <Card className="m-4">
        <Card.Body>
          <Table responsive striped bordered hover className="mt-2">
            <thead>
              <th>{`Current -- Account Balance ${
                walletBalance || 0
              } / Wagering ${
                account.wager?.withdrawWager ? account.wager?.withdrawWager : 0
              }`}</th>
              <tr>
                <th>{"Manual Payout"}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <ManualWithdraw
                    refreshAccount={refreshAccount}
                    onSubmit={handleManualTransactionSubmit}
                    account={account}
                  />
                </td>
              </tr>
            </tbody>
          </Table>
        </Card.Body>
      </Card>
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Transaction</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{transactionData?.type || ""}</p>
          <h5>Account name</h5>
          <p>{account.name || ""}</p>
          <h5>Amount</h5>
          <p>{transactionData?.amount || ""}</p>
          {transactionData?.removedAmountFromWagering !== null && (
            <>
              <h5>Removed Amount From Wagering</h5>
              <p>{transactionData?.removedAmountFromWagering}</p>
            </>
          )}
          {!isNaN(transactionData?.withdrawalFee) && (
            <>
              <h5>Withdrawal Fee</h5>
              <p>{transactionData?.withdrawalFee}</p>
            </>
          )}
          <h5>Comment</h5>
          <p>{transactionData?.comment || ""}</p>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          <Button variant="danger" onClick={handleCloseModal}>
            Back
          </Button>
          <Button variant="primary" onClick={handleSendRequest}>
            Send
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ManualTransaction;

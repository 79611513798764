/* eslint-disable react-hooks/exhaustive-deps */
import React, { ChangeEvent, FunctionComponent, useEffect, useState } from "react";
import { Alert, Button, Form, Modal } from "react-bootstrap";
import { AccountDocument } from "../../../models/app/AccountDocument";
import { AccountDocumentTypeCodes } from "../../../models/app/AccountDocumentType";
import { makeRequest } from "../../../utilities/axio.helper";
import {
  RequestUpdateExpiryDateDto,
} from "../../../models/dto/document.dto";
import urls from "../../../utilities/urls";
import Moment from "moment";
import { PaymentAccount } from "src/models/app/PaymentAccount";
import PaymentOption from "src/components/inputs/PaymentOptionInput";
import DocumentSide from "src/components/inputs/DocumentSideInput";

export interface IDocumentApprovalModal {
  document: AccountDocument;
  removeModal: () => void;
  onUpdated: () => void;
  paymentAccounts: PaymentAccount[];
}

export const DocumentApprovalModal: FunctionComponent<
  IDocumentApprovalModal
> = (props: IDocumentApprovalModal) => {
  const [validDocumentType, setValidDocumentType] = useState<boolean>(true);
  const [isSOFChecked, setSOFChecked] = useState<boolean>(false);
  const [isPOFChecked, setPOFChecked] = useState<boolean>(false);
  const [isPOIChecked, setPOIChecked] = useState<boolean>(false);
  const [isPOAChecked, setPOAChecked] = useState<boolean>(false);

  const [isPOF_FrontChecked, setPOF_FrontChecked] = useState<boolean>(false);
  const [isPOF_BackChecked, setPOF_BackChecked] = useState<boolean>(false);
  const [paymentOption, setPaymentOption] = useState("")
  const [isSkrillActive, setSkrillActive] = useState<boolean>(false);

  const [expiryDate, setExpireDate] = useState<Date>(
    Moment("2025-02-28", "YYYY-MM-DD").toDate()
  );

  useEffect(() => {
    if(!isPOFChecked){
      setPaymentOption("")
      setPOF_FrontChecked(false)
      setPOF_BackChecked(false)
    }
  }, [isPOFChecked]);

  useEffect(() => {

    if(props.document.expiryDate){
      setExpireDate(Moment(props.document.expiryDate, "YYYY-MM-DD").toDate())
    }

    if(props.document.paymentOption){
      setPaymentOption(props.document.paymentOption)
    }

    if (props.document.documentSide) {
      if (props.document.documentSide === "Front") {
        setPOF_FrontChecked(true);
      } else if (props.document.documentSide === "Back") {
        setPOF_BackChecked(true);
      }
    }
  }, [])

  useEffect(() => {
    if(paymentOption === "Skrill"){
      setSkrillActive(true)
      setPOF_FrontChecked(false)
      setPOF_BackChecked(false)
    }else{
      setSkrillActive(false)
    }
  }, [paymentOption])

  useEffect(() => {
    if(!props.document.expiryDate){
      if(isPOAChecked){
        setExpireDate(Moment().add("1", "year").toDate())
      }
      else{
        setExpireDate(Moment("2025-02-28", "YYYY-MM-DD").toDate())
      }
    }
  }, [isPOAChecked, isPOIChecked, isPOFChecked, isSOFChecked])

  const onHide = (): void => props.removeModal();

  const onSave = async (): Promise<void> => {
    try {
      const documentTypes: string[] = [];
      if (!expiryDate || expiryDate.toString() === "Invalid Date") {
        toastr.error("Please select an expiry date");
        return;
      }

      if (props.document.stateId === 3) {
        if(props.document.typeId === 4){
          if(!isSkrillActive && (!isPOF_FrontChecked && !isPOF_BackChecked)){
            setValidDocumentType(false);
            toastr.error("Please select a document side");
            return;
          }

          if (paymentOption === ''){
            setValidDocumentType(false);
            toastr.error("Please input a payment Option");
            return;
          }
        }

        await makeRequest<AccountDocument, RequestUpdateExpiryDateDto>(
          "post",
          urls.DOCUMENTS_UPDATE,
          {
            documentId: props.document.id,
            expiryDate: expiryDate,
            paymentOption: paymentOption,
            documentSide: !isSkrillActive ? (isPOF_FrontChecked ? "Front" : isPOF_BackChecked ? "Back" : "") : ""
          },
        );

        props.onUpdated();

        toastr.success("Document was updated!");

        onHide();
      } else {
        if (isSOFChecked) {
          documentTypes.push(AccountDocumentTypeCodes.SOURCE_OF_FUNDS);
        }
        if (isPOFChecked) {
          if(((isPOF_FrontChecked || isPOF_BackChecked) && paymentOption) || (paymentOption === "Skrill")){
            documentTypes.push(AccountDocumentTypeCodes.PROOF_OF_FUNDS);
          }
        }
        if (isPOIChecked) {
          documentTypes.push(AccountDocumentTypeCodes.PROOF_OF_IDENTITY);
        }
        if (isPOAChecked) {
          documentTypes.push(AccountDocumentTypeCodes.PROOF_OF_ADDRESS);
        }

        if(isPOFChecked && !isSkrillActive && (!isPOF_FrontChecked && !isPOF_BackChecked)){
          setValidDocumentType(false);
          toastr.error("Please select a document side");
          return;
        }
        
        if (isPOFChecked && paymentOption === ""){
          setValidDocumentType(false);
          toastr.error("Please input a payment Option");
          return;
        }
        
        if (!documentTypes || documentTypes.length === 0) {
          setValidDocumentType(false);
          toastr.error("Please select a document type");
          return;
        }

        await makeRequest<AccountDocument, any>(
          "post",
          urls.DOCUMENTS_VERIFY,
          {
            documentId: props.document.id,
            documentTypeCodes: documentTypes,
            expiryDate: expiryDate,
            paymentOption: paymentOption,
            documentSide: isPOFChecked && !isSkrillActive ? (isPOF_FrontChecked ? "Front" : "Back") : ""
          },
        );

        props.onUpdated();

        toastr.success("Document was approved!");

        

        onHide();
      }
    } catch (err) {
      toastr.error((err as any).message);
    }
  };

  const handlePaymentOptionInput = (e: ChangeEvent<HTMLInputElement>) => {
    setPaymentOption(e.target.value)
  }

  const renderModal = (): React.ReactChild => {

    return (
      <Modal show={true} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>
            {props.document.stateId === 3
              ? "Update Document"
              : "Approve Document"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {props.document.stateId !== 3 && (
              <Form.Group controlId="approval">
                <Form.Label>Please select the document type</Form.Label>
                <Form.Check
                  label="Source of Funds"
                  name={AccountDocumentTypeCodes.SOURCE_OF_FUNDS}
                  type="checkbox"
                  key={AccountDocumentTypeCodes.SOURCE_OF_FUNDS}
                  id={AccountDocumentTypeCodes.SOURCE_OF_FUNDS}
                  checked={isSOFChecked}
                  onChange={(
                    evt: React.ChangeEvent<HTMLInputElement>,
                  ): void => {
                    if (evt.target) {
                      if (evt.target.checked) {
                        setSOFChecked(true);
                      } else {
                        setSOFChecked(false);
                      }
                    }
                  }}
                />
                <Form.Check
                  label="Proof of Identity"
                  name={AccountDocumentTypeCodes.PROOF_OF_IDENTITY}
                  type="checkbox"
                  key={AccountDocumentTypeCodes.PROOF_OF_IDENTITY}
                  id={AccountDocumentTypeCodes.PROOF_OF_IDENTITY}
                  checked={isPOIChecked}
                  onChange={(
                    evt: React.ChangeEvent<HTMLInputElement>,
                  ): void => {
                    if (evt.target) {
                      if (evt.target.checked) {
                        setPOIChecked(true);
                      } else {
                        setPOIChecked(false);
                      }
                    }
                  }}
                />
                <Form.Check
                  label="Proof of Address"
                  name={AccountDocumentTypeCodes.PROOF_OF_ADDRESS}
                  type="checkbox"
                  key={AccountDocumentTypeCodes.PROOF_OF_ADDRESS}
                  id={AccountDocumentTypeCodes.PROOF_OF_ADDRESS}
                  checked={isPOAChecked}
                  onChange={(
                    evt: React.ChangeEvent<HTMLInputElement>,
                  ): void => {
                    if (evt.target) {
                      if (evt.target.checked) {
                        setPOAChecked(true);
                      } else {
                        setPOAChecked(false);
                      }
                    }
                  }}
                />
                <Form.Check
                  label="Proof of Funds"
                  name={AccountDocumentTypeCodes.PROOF_OF_FUNDS}
                  type="checkbox"
                  key={AccountDocumentTypeCodes.PROOF_OF_FUNDS}
                  id={AccountDocumentTypeCodes.PROOF_OF_FUNDS}
                  checked={isPOFChecked}
                  onChange={(
                    evt: React.ChangeEvent<HTMLInputElement>,
                  ): void => {
                    if (evt.target) {
                      if (evt.target.checked) {
                        setPOFChecked(true);
                      } else {
                        setPOFChecked(false);
                      }
                    }
                  }}
                />
                {isPOFChecked ? 
                <Form.Group style={{marginLeft: "1rem"}}>
                  <DocumentSide isPOF_FrontChecked={isPOF_FrontChecked}
                            isPOF_BackChecked={isPOF_BackChecked}
                            disabledCheckbox={isSkrillActive}
                            setPOF_FrontChecked={setPOF_FrontChecked}
                            setPOF_BackChecked={setPOF_BackChecked}
                  />
                  <PaymentOption document={props.document} 
                                paymentAccounts={props.paymentAccounts} 
                                handlePaymentOptionInput={handlePaymentOptionInput}
                  />
                </Form.Group> : ""}
              </Form.Group>
            )}
            <Form.Group controlId="edit">
              <Form.Label>Please select the expire date</Form.Label>
              <Form.Row>
                <Form.Control
                  type="date"
                  placeholder="ex. 24/05/1997"
                  value={
                    Moment(expiryDate).format("YYYY-MM-DD")
                  }
                  onChange={(evt: any): void => {
                    if (evt.target) {
                      setExpireDate(
                        Moment(evt.target.value, "YYYY-MM-DD").toDate(),
                      );
                    }
                  }}
                />
              </Form.Row>
              {props.document.type.code === AccountDocumentTypeCodes.PROOF_OF_FUNDS ? 
              <Form.Group style={{marginTop: "1em"}}>
              <DocumentSide isPOF_FrontChecked={isPOF_FrontChecked}
                            isPOF_BackChecked={isPOF_BackChecked}
                            disabledCheckbox={isSkrillActive}
                            setPOF_FrontChecked={setPOF_FrontChecked}
                            setPOF_BackChecked={setPOF_BackChecked}
              />
              <PaymentOption document={props.document} 
                            paymentAccounts={props.paymentAccounts} 
                            handlePaymentOptionInput={handlePaymentOptionInput}
              />
              </Form.Group> : ""
              }
            </Form.Group>
          </Form>
          {!validDocumentType && (
            <Alert variant={"danger"}>Please review the details</Alert>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              onSave();
            }}
          >
            Approve
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  return <>{renderModal()}</>;
};

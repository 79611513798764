import React, { FunctionComponent } from "react";
import { Card, Col, Form, Row } from "react-bootstrap";
import { PaymentAccount } from "src/models/app/PaymentAccount";
import { Account } from "src/models/app/Account";
import { PaymentMethods } from "./PaymentMethods";
import { makeRequest } from "src/utilities/axio.helper";
import urls from "src/utilities/urls";

interface IPaymentAccountsProps {
  account: Account;
  paymentAccount: PaymentAccount;
  updatePaymentAccounts: () => void;
}

export const PaymentAccountsItem: FunctionComponent<IPaymentAccountsProps> = (
  props: IPaymentAccountsProps,
) => {
  return (
    <Card border={props.paymentAccount.verified ? "success" : "danger"}>
      <Card.Header>
        <Row>
          <Col sm={"11"}>{`Payment Account [${props.paymentAccount.id}]`} </Col>
          <Col>
            <Form.Group controlId="verified">
              <Form.Check
                type="switch"
                checked={props.paymentAccount.verified}
                label={`${props.paymentAccount.verified ? "✔" : "⚠"} Verified`}
                id={`${props.paymentAccount.id}-paymentAccount-toggle`}
                onChange={async (ev) => {
                  try {
                    await makeRequest(
                      "post",
                      ev.target.checked
                        ? urls.PAYMENT_ACCOUNTS_VERIFY
                        : urls.PAYMENT_ACCOUNTS_UNVERIFY,
                      {
                        accountId: props.account.id,
                        paymentAccountId: props.paymentAccount.id,
                      },
                    );
                    props.updatePaymentAccounts();
                  } catch (e) {
                    toastr.error(e as any);
                  }
                }}
              />
            </Form.Group>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body>
        <Form.Group>
          <Form.Label>Identifier</Form.Label>
          <Form.Control
            readOnly
            type="text"
            value={props.paymentAccount.identifier}
          />
        </Form.Group>
        <Card>
          <Card.Header>Data</Card.Header>
          <Card.Body>
            <pre>{JSON.stringify(props.paymentAccount.meta, null, 2)}</pre>
          </Card.Body>
        </Card>
        <br />
        <Card>
          <Card.Header>Payment Methods</Card.Header>
          <Card.Body>
            <PaymentMethods
              paymentMethodPaymentAccounts={
                props.paymentAccount.paymentMethodPaymentAccounts ?? []
              }
            />
          </Card.Body>
        </Card>
      </Card.Body>
    </Card>
  );
};

import React, {
  ChangeEvent,
  FunctionComponent,
  useEffect,
  useState,
} from "react";
import { Form, Button, Card } from "react-bootstrap";
import { FileUpload } from "../../../components/fileUpload/FileUpload";
import { Account } from "src/models/app/Account";

export interface IProps {
  onSubmit: (data: any) => void;
  account: Account;
  refreshAccount: () => void;
}

export enum PaymentMethodCodes {
  MASTERCARD = "mastercard",
  VISA = "visa",
  SKRILL_WALLET = "skrill-wallet",
}

export const ManualWithdraw: FunctionComponent<IProps> = ({
  onSubmit,
  account,
  refreshAccount,
}) => {
  const [amount, setAmount] = useState("");
  const [modifyWagering, setModifyWagering] = useState(false);
  const [feeOption, setFeeOption] = useState(0);
  const [withdrawFee, setWithdrawFee] = useState("");
  const [comment, setComment] = useState("");
  const [paymentMethod, setPaymentMethod] = useState<string>(
    PaymentMethodCodes.MASTERCARD,
  );

  const renderpaymentMethods = () => {
    return Object.values(PaymentMethodCodes).map(
      (method: PaymentMethodCodes, index: number) => {
        return (
          <option key={index} value={method}>
            {method}
          </option>
        );
      },
    );
  };

  useEffect(() => {
    const calculateWithdrawFee = () => {
      const amountValue = parseFloat(amount);
      const feePercentage = feeOption / 100;
      const calculatedWithdrawFee = amountValue * feePercentage;
      setWithdrawFee(calculatedWithdrawFee.toFixed(2));
    };

    calculateWithdrawFee();
  }, [amount, feeOption]);

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    onSubmit({
      amount: Number(amount),
      modifyWagering,
      withdrawFee,
      comment,
      paymentMethod,
    });
    setAmount("");
    setModifyWagering(false);
    setFeeOption(0);
    setWithdrawFee("");
    setComment("");
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group controlId="debitAmount">
        <Form.Label>Debit Amount</Form.Label>
        <Form.Control
          type="number"
          placeholder="Enter debit amount"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          required
        />
      </Form.Group>
      <Form.Group controlId="removeFromWagering">
        <Form.Check
          type="checkbox"
          label="Remove Amount from Wagering"
          checked={modifyWagering}
          onChange={(e) => setModifyWagering(e.target.checked)}
        />
      </Form.Group>
      <Form.Group controlId="feeOption">
        <Form.Check
          type="radio"
          label="0%"
          name="feeOption"
          checked={feeOption === 0}
          onChange={() => setFeeOption(0)}
        />
        <Form.Check
          type="radio"
          label="1.4%"
          name="feeOption"
          checked={feeOption === 1.4}
          onChange={() => setFeeOption(1.4)}
        />
        <Form.Check
          type="radio"
          label="2.8%"
          name="feeOption"
          checked={feeOption === 2.8}
          onChange={() => setFeeOption(2.8)}
        />
      </Form.Group>
      <Form.Group controlId="withdrawFee">
        <Form.Label>Withdraw Fee</Form.Label>
        <Form.Control
          type="number"
          placeholder="Withdraw fee"
          value={withdrawFee}
          onChange={(e) => setWithdrawFee(e.target.value)}
          readOnly
        />
      </Form.Group>
      <Form.Group controlId="paymentMethod">
        <Form.Label>Payment Method</Form.Label>
        <Form.Control
          placeholder="Select Payment Method"
          as="select"
          custom
          defaultValue={paymentMethod}
          onChange={(option: ChangeEvent<HTMLSelectElement>): void => {
            setPaymentMethod(option.target.value);
          }}
        >
          {renderpaymentMethods()}
        </Form.Control>
      </Form.Group>
      <Form.Group controlId="commentsDebit">
        <Form.Label>Comments</Form.Label>
        <Form.Control
          as="textarea"
          rows={3}
          placeholder="Enter comments"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          required
        />
      </Form.Group>
      <Card.Header>Upload Document</Card.Header>
      <FileUpload account={account} refreshAccount={refreshAccount} />
      <Button type="submit">Send</Button>
    </Form>
  );
};

export default ManualWithdraw;

/**
 //////////////////////////////////////////////////////////////////////
 THIS FILE WAS GENEREATED
 Run 'npm run export-models' on backend to sync models
 ((DO NOT MODIFIY!!!))
 //////////////////////////////////////////////////////////////////////
 */

import { Transaction } from "../app/Transaction";

export class GetAccountTransactionsWhere {
  type?: string;

  status?: string;

  createdAt?: string;
}
export class GetAccountMonetaryTransactionsWhere {
  type?: string;

  status?: string;

  createdAt?: string;
}
export class GetAccountGameTransactionsWhere {
  gameName?: string;

  roundId?: string;

  createdAt?: string;
}
export class RequestGetAccountGameTransactionsDto {
  where?: GetAccountGameTransactionsWhere;

  accountId: number;

  limit?: number;

  offset?: number;
}
export class RequestGetAccountTransactionsDto {
  where?: GetAccountTransactionsWhere;

  accountId: number;

  limit?: number;

  offset?: number;
}

export class GetTransactionsWhere {
  id?: number[];

  accountId: number;

  type?: number[];

  states?: number[];

  withDeleted: boolean;

  gameName?: string[];
}

export class RequestGetTransactionsDto {
  accountId: number;

  limit?: number;

  offset?: number;

  where?: GetTransactionsWhere;
}

export class PaginatedTransaction {
  transactions: Transaction[];
  totalTransactions: number;
}
export class ResponseGetTransactionsDto {
  payment: PaginatedTransaction;

  casino: PaginatedTransaction;
}
export class RequestGetTransactionTotalsDto {
  accountId: number;
}

export class ResponseGetTransactionTotalsDto {
  totalWithdrawals: number;
  totalDeposit: number;
}

export class RequestGetPendingTransactionsDto {
  limit?: number;

  offset?: number;

  where?: GetTransactionsWhere;
}

export class ResponseGetPendingTransactionsDto {
  pendingWithdrawals: PaginatedTransaction;
}

export class CreateManualTransactionDto {
  amount: number;
  removedAmountFromWagering: number;
  comment: string;
  paymentMethod: string;
  withdrawalFee: number;
  accountId: number;
}

export class RequestGetPaymentAccountDto {
  accountId: number;
}

/**
    //////////////////////////////////////////////////////////////////////
    THIS FILE WAS GENEREATED
    Run 'npm run export-models' on backend to sync models
    ((DO NOT MODIFIY!!!))
    //////////////////////////////////////////////////////////////////////
    */

// Models
import { Wallet } from "./Wallet";

export enum WalletTypes {
  REAL = 1,
  BONUS = 2,
}

export class WalletType {
  id: number;

  name: string;

  createdAt: Date;

  updatedAt: Date;

  deletedAt: Date | null;

  wallets?: Wallet[];
}

import React, { FunctionComponent } from "react";
import { Table } from "react-bootstrap";
import TableRow from "./TableRow";
import { FreeBet } from "src/models/app/FreeBet";

interface IFreeBetTable {
  freeBets: FreeBet[];
  refreshData: any;
}

const FreeSpinsTable: FunctionComponent<IFreeBetTable> = (
  props: IFreeBetTable,
) => {
  return (
    <Table hover className="bg-white">
      <thead>
        <tr className="text-center">
          <th>User Id</th>
          <th>Operator</th>
          <th>Promo Name</th>
          <th>Game Name</th>
          <th>Amount</th>
          <th>Rounds</th>
          <th>Rounds Used</th>
          <th>Create At</th>
          <th>Start At</th>
          <th>Expire At</th>
        </tr>
      </thead>
      <tbody>
        {props.freeBets.map((freeBet: FreeBet, index) => {
          return freeBet ? (
            <TableRow
              freeBet={freeBet}
              index={index}
              refreshData={props.refreshData}
            />
          ) : (
            <></>
          );
        })}
      </tbody>
    </Table>
  );
};

export default FreeSpinsTable;

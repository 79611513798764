import React, { FunctionComponent } from "react";
import { Account } from "src/models/app/Account";
import { Card, Table } from "react-bootstrap";
import urls from "../../../utilities/urls";

interface IStatusLogsProps {
  account: Account;
}
const AccountLinks: FunctionComponent<IStatusLogsProps> = (
  props: IStatusLogsProps,
) => {
  return (
    <>
      <h2 style={{ marginLeft: "2rem" }}>Links</h2>
      <Card className="m-4">
        <Card.Body>
          <Table responsive striped bordered hover className="mt-5">
            <thead>
              <tr>
                <th>{"Name"}</th>
                <th>{"Link"}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Metabase profile</td>
                <td>
                  <a
                    href={`${urls.METABASE_CLIENT_PROFILE}${props.account.id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Client Profile
                  </a>
                </td>
              </tr>
              <tr>
                <td>Metabase journey</td>
                <td>
                  <a
                    href={`${urls.METABASE_CLIENT_PROFILE_JOURNEY}${props.account.id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Client Journey
                  </a>
                </td>
              </tr>
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </>
  );
};

export default AccountLinks;

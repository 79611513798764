import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { GoogleOAuthProvider } from "@react-oauth/google";

import config from "./config";
// eslint-disable-next-line no-console
console.log(`Environment: ${config.ENVIRONMENT}`);

ReactDOM.render(
  // <React.StrictMode>
  <GoogleOAuthProvider
    onScriptLoadSuccess={() => {
      console.log("Google AUTH-0 Script Success");
    }}
    onScriptLoadError={() => {
      console.log("Google AUTH-0 Script Failed");
    }}
    clientId={config.GOOGLE_AUTH_CLIENT_ID}
  >
    <App />
  </GoogleOAuthProvider>,
  // </React.StrictMode>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// Models
import { AccountSnapShot } from "./AccountSnapShot";
import { BonusGameTransaction } from "./BonusGameTransaction";
import {
  BonusTransactionType,
  BonusTransactionTypes as BTTypes,
} from "./BonusTransactionType";
import {
  BonusTransactionState,
  BonusTransactionStates as BTStates,
} from "./BonusTransactionState";
import { ParentChildBonusTransaction } from "./ParentChildBonusTransaction";
import { Wallet } from "./Wallet";
import { Bonus } from "./Bonus";
import { Account } from "./Account";

export class BonusTransaction {
  id: number;

  walletId: number;

  wallet?: Wallet;

  accountId: number;

  account?: Account;

  bonusId: number;

  bonus: Bonus;

  typeId: BTTypes;

  type?: BonusTransactionType;

  stateId: BTStates;

  state?: BonusTransactionState;

  balanceBefore: number;

  debit: number;

  credit: number;

  balanceAfter: number;

  createdAt: Date;

  updatedAt: Date;

  deletedAt: Date | null;

  bonusGameTransaction?: BonusGameTransaction;

  parentOf: ParentChildBonusTransaction[];

  childOf: ParentChildBonusTransaction[];

  lastBonusSpinSnapShot?: AccountSnapShot;
}

export enum BonusTransactionTypes {
  SPIN = "SPIN",
  CHARGE = "CHARGE",
  REFUND = "REFUND",
  CORRECTION = "CORRECTION",
  RESET = "RESET",
}

export enum BonusTransactionStates {
  PROCESSED = "PROCESSED",
  CANCELED = "CANCELED",
  FAILED = "FAILED",
  REFUNDED = "REFUNDED",
  CORRECTED = "CORRECTED",
}

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-invalid-this */
import React from "react";
import { Theme } from "src/models/app/Theme";
import { ThemeCreateModal } from "../ThemeCreateModal";
import { ThemeListItem } from "./ThemeListItem";
import {
  RequestGetThemeDto,
  ResponseGetThemeDto,
} from "src/models/dto/theme.dto";
import { makeRequest } from "src/utilities/axio.helper";
import urls from "src/utilities/urls";
import { Card, Button, Table } from "react-bootstrap";

type ThemesListState = {
  themes: Theme[];
  showCreateModal: boolean;
};

export class ThemesList extends React.Component<
  Record<string, unknown>,
  ThemesListState
> {
  constructor(props: Record<string, unknown>) {
    super(props);
    this.state = { themes: [], showCreateModal: false };
  }
  fetchData = async (): Promise<void> => {
    const response: ResponseGetThemeDto = await makeRequest<
      ResponseGetThemeDto,
      RequestGetThemeDto
    >("post", urls.THEMES_LIST);
    this.setState({ themes: response.themes });
  };

  componentDidMount = () => {
    this.fetchData();
  };

  showCreateLimitModal = (): void => {
    this.setState({ showCreateModal: true });
  };
  hideCreateLimitModal = (): void => {
    this.setState({ showCreateModal: false });
  };

  onCreatedTheme = (theme: Theme) => {
    this.fetchData();
  };

  getCreateThemeModal = (): React.ReactChild => {
    if (this.state.showCreateModal) {
      return (
        <ThemeCreateModal
          removeModal={this.hideCreateLimitModal}
          onCreated={this.onCreatedTheme}
        />
      );
    }

    return <></>;
  };

  onDeletedTheme = (theme: Theme): void => {
    this.fetchData();
  };

  onUpdatedTheme = (theme: Theme): void => {
    this.fetchData();
  };

  render() {
    return (
      <>
        <Card>
          <Card.Header>
            Theme List
            <Button
              className="float-right"
              variant="primary"
              onClick={this.showCreateLimitModal}
            >
              Create Theme
            </Button>
          </Card.Header>
          <Card.Body>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Code</th>
                  <th>Active</th>
                  <th>Created At</th>
                  <th>Updated At</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {this.state.themes.map((theme: Theme) => (
                  <ThemeListItem
                    key={theme.id}
                    theme={theme}
                    onDeleted={this.onDeletedTheme}
                    onUpdated={this.onUpdatedTheme}
                  />
                ))}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
        {this.getCreateThemeModal()}
      </>
    );
  }
}

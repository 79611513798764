/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-invalid-this */
import React from "react";
import { Category } from "src/models/app/Category";
import { CategoryCreateModal } from "../CategoryCreateModal";
import { CategoryListItem } from "./CategoryListItem";
import {
  RequestGetCategoryDto,
  ResponseGetCategoryDto,
} from "src/models/dto/category.dto";
import { makeRequest } from "src/utilities/axio.helper";
import urls from "src/utilities/urls";
import { Card, Button, Table } from "react-bootstrap";

type CategoriesListState = {
  categories: Category[];
  showCreateModal: boolean;
};

export class CategoriesList extends React.Component<
  Record<string, unknown>,
  CategoriesListState
> {
  constructor(props: Record<string, unknown>) {
    super(props);
    this.state = { categories: [], showCreateModal: false };
  }
  fetchData = async (): Promise<void> => {
    const response: ResponseGetCategoryDto = await makeRequest<
      ResponseGetCategoryDto,
      RequestGetCategoryDto
    >("post", urls.CATEGORIES_LIST);
    this.setState({ categories: response.categories });
  };

  componentDidMount = () => {
    this.fetchData();
  };

  showCreateLimitModal = (): void => {
    this.setState({ showCreateModal: true });
  };
  hideCreateLimitModal = (): void => {
    this.setState({ showCreateModal: false });
  };

  onCreatedCategory = (category: Category) => {
    this.fetchData();
  };

  getCreateCategoryModal = (): React.ReactChild => {
    if (this.state.showCreateModal) {
      return (
        <CategoryCreateModal
          removeModal={this.hideCreateLimitModal}
          onCreated={this.onCreatedCategory}
        />
      );
    }

    return <></>;
  };

  onDeletedCategory = (category: Category): void => {
    this.fetchData();
  };

  onUpdatedCategory = (category: Category): void => {
    this.fetchData();
  };

  render() {
    return (
      <>
        <Card>
          <Card.Header>
            Account Limits
            <Button
              className="float-right"
              variant="primary"
              onClick={this.showCreateLimitModal}
            >
              Create Category
            </Button>
          </Card.Header>
          <Card.Body>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Code</th>
                  <th>Active</th>
                  <th>Created At</th>
                  <th>Updated At</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {this.state.categories.map((category: Category) => (
                  <CategoryListItem
                    key={category.id}
                    category={category}
                    onDeleted={this.onDeletedCategory}
                    onUpdated={this.onUpdatedCategory}
                  />
                ))}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
        {this.getCreateCategoryModal()}
      </>
    );
  }
}

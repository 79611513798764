import React, { FunctionComponent } from "react";
import FreeSpinsList from "../FreeSpinsListPage/FreeSpinsList";
import { Game } from "../../../models/app/Game";
import { Route, Switch, useHistory } from "react-router-dom";
import FreeSpinsEditPage from "../FreeSpinsEditPage/FreeSpinsEditPage";
import urls from "../../../utilities/urls";
import { makeRequest } from "../../../utilities/axio.helper";
import { DeleteGameDto } from "src/models/dto/game.dto";
import { Jumbotron, Button } from "react-bootstrap";

const FreeSpinsPage: FunctionComponent = () => {
  const history = useHistory();
  const deleteGame = async (gameId: number): Promise<void> => {
    try {
      await makeRequest<Game, DeleteGameDto>("delete", urls.GAMES_LIST, {
        id: gameId,
      });
    } catch (err) {
      toastr.error(err.message);
    }
  };

  const addPromo = async (): Promise<void> => {
    try {
      history.push("/free-spins/edit");
    } catch (err) {
      toastr.error(err.message);
    }
  };

  return (
    <div>
      <Switch>
        <Route path="/free-spins/edit">
          <FreeSpinsEditPage />
        </Route>
        <Route path="/free-spins">
          <Jumbotron className="m-4">
            <h3>Free Spins List</h3>
            <Button onClick={() => addPromo()}>Add Promo</Button>
            <FreeSpinsList deleteGame={deleteGame} />
          </Jumbotron>
        </Route>
      </Switch>
    </div>
  );
};

export default FreeSpinsPage;

import React, { FunctionComponent, useState, useEffect } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { RequestUpdateGameThemeDto } from "src/models/dto/game.theme.dto";
import { makeRequest } from "src/utilities/axio.helper";
import urls from "src/utilities/urls";
import { GameTheme } from "src/models/app/GameTheme";

export interface IGameThemeUpdateModal {
  gameTheme: GameTheme;
  removeModal: () => void;
  onUpdate: (gameTheme: GameTheme) => void;
}

export const GameThemeUpdateModal: FunctionComponent<IGameThemeUpdateModal> = (
  props: IGameThemeUpdateModal,
) => {
  const [updatedTheme, setUpdatedTheme] = useState<GameTheme>(new GameTheme());

  useEffect(() => {
    setUpdatedTheme(props.gameTheme);
  }, [props.gameTheme]);

  const onHide = (): void => props.removeModal();

  const onSave = async (): Promise<void> => {
    try {
      // Create Limit
      await makeRequest<GameTheme, RequestUpdateGameThemeDto>(
        "post",
        urls.GAME_THEMES,
        {
          ...updatedTheme,
        },
      );

      toastr.success("Updated Game Theme!");

      props.onUpdate(updatedTheme);
      onHide();
    } catch (err) {
      toastr.error(err.message);
    }
  };

  const renderModal = (): React.ReactChild => {
    return (
      <Modal show={true} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Update Game Theme</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="weight">
              <Form.Label>Weight</Form.Label>
              <Form.Control
                type="number"
                value={updatedTheme.weight}
                onChange={(e) => {
                  setUpdatedTheme({
                    ...updatedTheme,
                    weight: Number(e.target.value),
                  });
                }}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={onSave}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  return <>{renderModal()}</>;
};

/**
    //////////////////////////////////////////////////////////////////////
    THIS FILE WAS GENEREATED
    Run 'npm run export-models' on backend to sync models
    ((DO NOT MODIFIY!!!))
    //////////////////////////////////////////////////////////////////////
    */

/* -------------------------------- Models -------------------------------- */
import { Game } from "./Game";
import { Theme } from "./Theme";

export class GameTheme {
  gameId: number;

  themeId: number;

  weight?: number;

  createdAt?: Date;

  deletedAt?: Date;

  game: Game;

  theme: Theme;
}

import React, { FunctionComponent, useEffect, useState } from "react";
import { Game } from "../../../models/app/Game";
import { useHistory } from "react-router-dom";
import { Button, Card, Col, Form, Tabs, Tab } from "react-bootstrap";
import toastr from "toastr";
import { makeRequest } from "../../../utilities/axio.helper";
import config from "src/config";
import urls from "src/utilities/urls";
import { Account } from "src/models/app/Account";
import { Provider } from "src/models/app/Provider";
import moment from "moment";

const FreeSpinsEditPage: FunctionComponent = () => {
  const history = useHistory();
  const [game, setGame] = useState<Game>(new Game());
  const [games, setGames] = useState<Game[]>([]);
  const [users, setUsers] = useState<Account[]>([]);
  const [userId, setUserId] = useState(0);
  const [promoName, setPromoName] = useState("");
  const [amount, setAmount] = useState(2);
  const [expireDate, setExpireDate] = useState(
    moment(new Date(), "YYYY-MM-DD").startOf("day").toDate(),
  );
  const [rounds, setRounds] = useState(1);
  const [providers, setProviders] = useState<Provider[]>([]);
  const [provider, setProvider] = useState(new Provider());
  const currency = "DKK";

  useEffect(() => {
    const getProviders = async (): Promise<void> => {
      try {
        const responseProviders = await makeRequest(
          "post",
          urls.PROVIDERS,
          {},
          {
            username: config.API_AUTH.USERNAME,
            password: config.API_AUTH.PASSWORD,
          },
        );
        const validProviders = responseProviders.filter(
          (p: Provider) => p.id === 1, // Get just No Limit for now
        );
        setProviders(validProviders);
        setProvider(validProviders[0]);

        const accountsResponse = await makeRequest(
          "post",
          urls.ACCOUNTS_LIST,
          { where: { withDeleted: false, active: true } },
          {
            username: config.API_AUTH.USERNAME,
            password: config.API_AUTH.PASSWORD,
          },
        );
        setUsers(accountsResponse.accounts);
        setUserId(accountsResponse.accounts[0]?.id);
      } catch (err) {
        console.log(err);
      }
    };
    getProviders();
  }, []);

  useEffect(() => {
    const getGame = async (): Promise<void> => {
      try {
        const getGames = await makeRequest(
          "post",
          `${config.API_URL}/games/list`,
          { where: { active: 1, providers: [provider.id] } },
          {
            username: config.API_AUTH.USERNAME,
            password: config.API_AUTH.PASSWORD,
          },
        );
        setGames(getGames.games);
        setGame(getGames.games[0]);
      } catch (err) {
        console.log(err);
      }
    };
    if (provider.id) {
      getGame();
    }
  }, [provider]);

  const saveGame = async (): Promise<void> => {
    try {
      const gameName = game.externalId;

      const result = await makeRequest(
        "post",
        urls.NOLIMIT_ADD_FREE_SPINS,
        {
          userId,
          promoName,
          game: gameName,
          rounds,
          amount,
          currency,
          expires: moment(expireDate).add(1, "hours"),
        },
        {
          username: config.API_AUTH.USERNAME,
          password: config.API_AUTH.PASSWORD,
        },
      );
      if (result?.error) {
        toastr.error(result?.error?.data?.message);
      } else {
        toastr.success(`Promo ${promoName} Create!`);

        history.push("/free-spins");
      }
    } catch (err) {
      console.log(err);
      toastr.error((err as any).message);
    }
  };

  const checkData = () => {
    if (
      !provider ||
      !promoName ||
      !game ||
      !rounds ||
      !amount ||
      !expireDate ||
      !userId
    )
      return true;
    return false;
  };

  return (
    <Tabs defaultActiveKey="details" id="uncontrolled-tab-example">
      <Tab eventKey="details" title="Details">
        <Card>
          <Card.Header>Add Promo</Card.Header>
          <Card.Body>
            <Form>
              <Form.Row>
                <Form.Group as={Col} controlId="formProvider">
                  <Form.Label>Provider</Form.Label>
                  <Form.Control
                    as="select"
                    custom
                    value={provider?.name}
                    onChange={(
                      evt: React.ChangeEvent<HTMLInputElement>,
                    ): void => {
                      if (evt.target) {
                        const providerId = Number(evt.target.value);
                        const newProvider: Provider | undefined =
                          providers.find((p) => p.id === providerId) ||
                          new Provider();
                        setProvider(newProvider);
                      }
                    }}
                  >
                    {providers?.map((value: Provider) => (
                      <option key={value.id} value={value.id}>
                        {value.name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>

                <Form.Group as={Col} controlId="formGameCategories">
                  <Form.Label>Promotion Name</Form.Label>
                  <Form.Control
                    type="text"
                    value={promoName}
                    placeholder="Enter promo name"
                    onChange={(
                      evt: React.ChangeEvent<HTMLInputElement>,
                    ): void => {
                      if (evt.target) {
                        const name = evt.target.value;
                        setPromoName(name);
                      }
                    }}
                  />
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col} controlId="formGame">
                  <Form.Label>Game</Form.Label>
                  <Form.Control
                    as="select"
                    custom
                    value={game?.name}
                    onChange={(
                      evt: React.ChangeEvent<HTMLInputElement>,
                    ): void => {
                      if (evt.target) {
                        const gameName = evt.target.value;
                        const newGame: Game | undefined =
                          games.find((g) => g.name === gameName) || new Game();
                        setGame(newGame);
                      }
                    }}
                  >
                    {games?.map((value: Game) => (
                      <option key={value.id} value={value.name}>
                        {value.name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
                <Form.Group as={Col} controlId="formRounds">
                  <Form.Label>Rounds</Form.Label>
                  <Form.Control
                    type="number"
                    step="any"
                    min="1"
                    value={rounds}
                    onChange={(
                      evt: React.ChangeEvent<HTMLInputElement>,
                    ): void => {
                      const newRounds = Number(evt.target.value);
                      setRounds(newRounds);
                    }}
                  />
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col} controlId="formRounds">
                  <Form.Label>Amount</Form.Label>
                  <Form.Control
                    type="number"
                    step="any"
                    min="2"
                    value={amount}
                    onChange={(
                      evt: React.ChangeEvent<HTMLInputElement>,
                    ): void => {
                      const newAmount = Number(evt.target.value);
                      setAmount(newAmount);
                    }}
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="formexpireDate">
                  <Form.Label>Expire date</Form.Label>
                  <Form.Control
                    type="date"
                    placeholder="ex. 24/05/1997"
                    value={moment(expireDate).format("YYYY-MM-DD")}
                    onChange={(evt: any): void => {
                      if (evt.target) {
                        setExpireDate(
                          moment(evt.target.value, "YYYY-MM-DD")
                            .startOf("day")
                            .toDate(),
                        );
                      }
                    }}
                  />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} controlId="formUserId">
                  <Form.Label>User ID</Form.Label>
                  <Form.Control
                    as="select"
                    onChange={(
                      evt: React.ChangeEvent<HTMLInputElement>,
                    ): void => {
                      if (evt.target) {
                        const accountId = Number(evt.target.value);
                        setUserId(accountId);
                      }
                    }}
                  >
                    {users?.map((account: Account) => {
                      return (
                        <option key={account.id} value={account.id}>
                          {account.id}
                        </option>
                      );
                    })}
                  </Form.Control>
                </Form.Group>
              </Form.Row>

              <Button
                variant="primary"
                onClick={saveGame}
                disabled={checkData()}
              >
                Save
              </Button>
            </Form>
          </Card.Body>
        </Card>
      </Tab>
    </Tabs>
  );
};

export default FreeSpinsEditPage;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { FunctionComponent, useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { Account } from "src/models/app/Account";
import { PaymentAccount } from "src/models/app/PaymentAccount";
import { makeRequest } from "src/utilities/axio.helper";
import urls from "src/utilities/urls";
import { PaymentAccountsItem } from "./PaymentAccountsItem";

interface IPaymentAccountsProps {
  account: Account;
  refreshAccount: () => void;
}

export const PaymentAccounts: FunctionComponent<IPaymentAccountsProps> = (
  props: IPaymentAccountsProps,
) => {
  const [paymentAccounts, setPaymentAccounts] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const updatePaymentAccounts = async () => {
    const result = await makeRequest("post", urls.PAYMENT_ACCOUNTS_LIST, {
      accountId: props.account.id,
    });

    setPaymentAccounts(result);
  };

  useEffect(() => {
    updatePaymentAccounts();
  }, [refresh]);

  return (
    <Card>
      <Card.Header>Payment Accounts</Card.Header>
      <Card.Body>
        {paymentAccounts.map((paymentAccount: PaymentAccount, key) => {
          return (
            <div key={`payment-account-${key}`}>
              <PaymentAccountsItem
                account={props.account}
                paymentAccount={paymentAccount}
                updatePaymentAccounts={() => {
                  setRefresh(!refresh);
                  props.refreshAccount();
                }}
                key={key}
              ></PaymentAccountsItem>
              <br />
            </div>
          );
        })}
      </Card.Body>
    </Card>
  );
};

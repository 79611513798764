/* eslint-disable react-hooks/exhaustive-deps */
import React, { FunctionComponent, useState, useEffect } from "react";
import { Button, Modal, Form, Alert } from "react-bootstrap";
import { DepositLimit } from "src/models/app/DepositLimit";
import { RequestUpdateDepositLimitDto } from "src/models/dto/depositLimit.dto";
import { makeRequest } from "src/utilities/axio.helper";
import urls from "src/utilities/urls";
import { Account } from "src/models/app/Account";

const MAX_DEPOSIT_LIMIT = 100_000;
const MIN_DEPOSIT_LIMIT = 100;

export interface IAccountDepositLimitUpdateModal {
  account: Account;
  removeModal: () => void;
  onUpdated: (depositLimits: DepositLimit[]) => void;
}

export const AccountDepositLimitUpdateModal: FunctionComponent<
  IAccountDepositLimitUpdateModal
> = (props: IAccountDepositLimitUpdateModal) => {
  const [validDL, setvalidDL] = useState<boolean>(true);

  const [dailyDL, setDailyDL] = useState<DepositLimit>(
    props.account.depositLimits[0],
  );

  const [weeklyDL, setWeeklyDL] = useState<DepositLimit>(
    props.account.depositLimits[1],
  );
  const [monthlyDL, setMonthlyDL] = useState<DepositLimit>(
    props.account.depositLimits[2],
  );

  const [newDailyDLAmount, setNewDailyDLAmount] = useState<number>(
    props.account.depositLimits[0].amount,
  );

  const [newWeeklyDLAmount, setNewWeeklyDLAmount] = useState<number>(
    props.account.depositLimits[1].amount,
  );
  const [newMonthlyDLAmount, setNewMonthlyDLAmount] = useState<number>(
    props.account.depositLimits[2].amount,
  );

  // Ensures the Weekly Deposit limit can never be less than the daily

  useEffect(() => {
    if (
      dailyDL.amount > newWeeklyDLAmount ||
      dailyDL.increase > newWeeklyDLAmount
    ) {
      const minDailyDLMin: number = dailyDL.increase
        ? dailyDL.increase
        : dailyDL.amount;
      setNewWeeklyDLAmount(minDailyDLMin);
      setWeeklyDL({
        ...weeklyDL,
        amount: minDailyDLMin,
      });
    }
  }, [setNewWeeklyDLAmount, newDailyDLAmount, newWeeklyDLAmount]);

  // Ensures the Monthly Deposit limit can never be less than the weekly

  useEffect(() => {
    if (
      weeklyDL.amount > newMonthlyDLAmount ||
      weeklyDL.increase > newMonthlyDLAmount
    ) {
      const minWeeklyDLMin: number = weeklyDL.increase
        ? weeklyDL.increase
        : weeklyDL.amount;
      setNewMonthlyDLAmount(minWeeklyDLMin);
      setMonthlyDL({
        ...monthlyDL,
        amount: newWeeklyDLAmount,
      });
    }
  }, [setMonthlyDL, newMonthlyDLAmount, newWeeklyDLAmount]);

  // Check valid limits

  useEffect(() => {
    const invalidDepositLimitAmount: boolean =
      [newDailyDLAmount, newWeeklyDLAmount, newMonthlyDLAmount].find(
        (limit) => limit < MIN_DEPOSIT_LIMIT || limit > MAX_DEPOSIT_LIMIT,
      ) !== undefined;
    setvalidDL(!invalidDepositLimitAmount);
  }, [newDailyDLAmount, newWeeklyDLAmount, newMonthlyDLAmount]);

  const onHide = (): void => props.removeModal();

  const onSave = async (): Promise<void> => {
    try {
      if (!validDL) {
        toastr.error("Invalid Deposit Limits");
        return;
      }
      // Create Limit
      const res = await makeRequest<
        DepositLimit[],
        RequestUpdateDepositLimitDto
      >("post", urls.DEPOSIT_LIMITS, {
        daily: dailyDL.amount,
        weekly: weeklyDL.amount,
        monthly: monthlyDL.amount,
        accountId: props.account.id,
      });

      props.onUpdated(res);

      toastr.success("Deposit limits update!");

      onHide();
    } catch (err) {
      toastr.error(err.message);
    }
  };

  const renderModal = (): React.ReactChild => {
    return (
      <Modal show={true} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Update Deposit Limit</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="DL">
              <Form.Label>New daily limit</Form.Label>
              <Form.Control
                type="number"
                placeholder="Daily limit"
                value={newDailyDLAmount}
                onChange={(evt: React.ChangeEvent<HTMLInputElement>): void => {
                  setNewDailyDLAmount(Number(evt.target.value));
                  setDailyDL({
                    ...dailyDL,
                    amount: Number(evt.target.value),
                  });
                }}
              />
            </Form.Group>
            <Form.Group controlId="WL">
              <Form.Label>New weekly limit</Form.Label>
              <Form.Control
                type="number"
                placeholder="Weekly limit"
                value={newWeeklyDLAmount}
                onChange={(evt: React.ChangeEvent<HTMLInputElement>): void => {
                  setNewWeeklyDLAmount(Number(evt.target.value));
                  setWeeklyDL({
                    ...weeklyDL,
                    amount: Number(evt.target.value),
                  });
                }}
              />
            </Form.Group>
            <Form.Group controlId="ML">
              <Form.Label>New monthly limit</Form.Label>
              <Form.Control
                type="number"
                placeholder="Monthly limit"
                value={newMonthlyDLAmount}
                onChange={(evt: React.ChangeEvent<HTMLInputElement>): void => {
                  setNewMonthlyDLAmount(Number(evt.target.value));
                  setMonthlyDL({
                    ...monthlyDL,
                    amount: Number(evt.target.value),
                  });
                }}
              />
            </Form.Group>
          </Form>

          {!validDL && (
            <Alert variant={"danger"}>
              Minimum deposit 100, maximum deposit 100000
            </Alert>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => {
              onSave();
            }}
          >
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  return <>{renderModal()}</>;
};

import React, { FunctionComponent, useEffect, useState } from "react";
import { Game } from "../../../models/app/Game";
import Multiselect from "react-multi-select-component";
import { Option } from "react-multi-select-component/dist/lib/interfaces";
import { Theme } from "../../../models/app/Theme";
import SpinnerComp from "src/components/spinner/SpinnerComp";

interface IGameThemeSelectorProps {
  game: Game;
  onChangeThemes: (themes: Theme[]) => void;
  singleSelect?: boolean;
  chosenThemes?: Theme[];
  themes: Theme[];
  defaultValue?: number;
}

const GameThemeSelector: FunctionComponent<IGameThemeSelectorProps> = (
  props: IGameThemeSelectorProps,
) => {
  const [chosenThemes, setChosenThemes] = useState<Option[]>([]);

  useEffect(() => {
    if (props.chosenThemes) {
      setChosenThemes(
        props.chosenThemes.map((themes: Theme): Option => {
          return {
            value: themes.id,
            label: themes.name,
          };
        }),
      );
    }
  }, [props.chosenThemes]);

  const getThemeById = (themesId: number): Theme =>
    props.themes?.filter((themes: Theme) => themes.id === themesId)[0];

  if (!props.themes) {
    return <SpinnerComp animation="grow" />;
  }

  return (
    <Multiselect
      options={props.themes.map((themes: Theme): Option => {
        return { label: themes.name, value: themes.id };
      })}
      value={chosenThemes}
      hasSelectAll={props.singleSelect === false}
      onChange={async (options: Option[]) => {
        if (props.singleSelect && options.length > 0) {
          const currentSelection = options[options.length - 1];
          options = [];
          options[0] = currentSelection;
        }

        if (options.length === 0 && props.defaultValue !== undefined) {
          options.push({
            label: props.themes[props.defaultValue].name,
            value: props.themes[props.defaultValue].id,
          });
        }
        setChosenThemes(options);

        if (props.onChangeThemes) {
          props.onChangeThemes(
            options.map((option: Option): Theme => getThemeById(option.value)),
          );
        }
      }}
      labelledBy={"Game Themes"}
    />
  );
};

GameThemeSelector.defaultProps = {
  singleSelect: false,
  chosenThemes: [],
  defaultValue: undefined,
};

export default GameThemeSelector;

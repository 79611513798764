import React, { FunctionComponent, useState } from "react";
import { ButtonGroup, Button } from "react-bootstrap";
import { GameTheme } from "src/models/app/GameTheme";
import { Game } from "src/models/app/Game";
import { GameThemeUpdateModal } from "./GameThemeUpdateModal";
import { safeFormatDate } from "src/utilities/date";

export interface IGameThemeListItem {
  gameTheme: GameTheme;
  game: Game;
  onUpdated: () => void;
}

export const GameThemeListItem: FunctionComponent<IGameThemeListItem> = (
  props: IGameThemeListItem,
) => {
  const [showUpdateModal, setShowUpdateModal] = useState<boolean>(false);

  const showUpdateLimitModal = (): void => setShowUpdateModal(true);
  const hideUpdateLimitModal = (): void => setShowUpdateModal(false);

  const getUpdateLimitModal = (): React.ReactChild => {
    if (showUpdateModal && props.gameTheme) {
      return (
        <GameThemeUpdateModal
          gameTheme={props.gameTheme}
          removeModal={hideUpdateLimitModal}
          onUpdate={props.onUpdated}
        />
      );
    }

    return <></>;
  };

  return (
    <>
      <tr key={props.game.id}>
        <td>{props.game.name}</td>
        <td>{props.gameTheme.theme.name}</td>
        <td>{props.gameTheme.weight}</td>
        <td>{safeFormatDate(props.gameTheme.createdAt)}</td>
        <td>
          <ButtonGroup
            className="float-sm-right mr-3"
            aria-label="Basic example"
          >
            <Button variant="info" onClick={showUpdateLimitModal}>
              Update
            </Button>
          </ButtonGroup>
        </td>
      </tr>
      {getUpdateLimitModal()}
    </>
  );
};

import React, {
  FunctionComponent,
  useState,
  Dispatch,
  SetStateAction,
} from "react";
import { Game } from "../../../models/app/Game";
import { Form } from "react-bootstrap";
import urls from "src/utilities/urls";
import { makeRequest } from "src/utilities/axio.helper";
import { UpdateGameDto } from "../../../models/dto/game.dto";

interface IProviderGamesRowComponent {
  game: Game;
  setGames: Dispatch<SetStateAction<Game[]>>;
}

const ProviderGamesRow: FunctionComponent<IProviderGamesRowComponent> = (
  props: IProviderGamesRowComponent,
) => {
  const [game, setGame] = useState<Game>(props.game);

  const [initialWeight, setInitialWeight] = useState<any>(
    props.game.providerWeight,
  );

  const updateProviderCategory = async (): Promise<void> => {
    try {
      await makeRequest<Game, UpdateGameDto>(
        "post",
        `${urls.GAMES_UPDATE}`,
        game,
      );
      setInitialWeight(game.providerWeight);
      props.setGames([]);
      toastr.success("Updated Weight!");
    } catch (err) {
      toastr.error(err.message);
    }
  };

  const changeWeight = () => {
    return (
      <Form.Control
        type="number"
        value={game.providerWeight}
        onChange={(evt: React.ChangeEvent<HTMLInputElement>): void => {
          setGame({
            ...game,
            providerWeight: Number(evt.target.value),
          });
        }}
        onBlur={(): void => {
          if (initialWeight !== game.providerWeight) updateProviderCategory();
        }}
        onKeyDown={(evt: React.KeyboardEvent<HTMLDivElement>) => {
          if (evt.key === "Enter") {
            if (initialWeight !== game.providerWeight) updateProviderCategory();
          }
        }}
      />
    );
  };

  return (
    <tr key={props.game.id}>
      <th>{props.game.id}</th>
      <th>{props.game.name}</th>
      <th>{changeWeight()}</th>
    </tr>
  );
};

export default ProviderGamesRow;

/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ChangeEvent, FunctionComponent } from "react";
import { AccountDocument } from "src/models/app/AccountDocument";
import { PaymentAccount } from "src/models/app/PaymentAccount";

export interface IDocumentSide {
    document: AccountDocument;
    handlePaymentOptionInput?: (e: ChangeEvent<HTMLInputElement>) => void;
    paymentAccounts: PaymentAccount[];
  }
const DocumentSide: FunctionComponent<IDocumentSide> = (
  props: IDocumentSide,
) => {

    return (
        <input defaultValue={props.document.paymentOption} type="text" onChange={props.handlePaymentOptionInput}/>
    )
};

export default DocumentSide;

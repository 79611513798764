import React, { FunctionComponent, useState, useEffect } from "react";
import { Game } from "../../../models/app/Game";
import { Category } from "src/models/app/Category";
import { Theme } from "src/models/app/Theme";

import { Button, Col, Form } from "react-bootstrap";
import { GameCategory } from "src/models/app/GameCategory";
import { GameTheme } from "src/models/app/GameTheme";

import GameCategorySelector from "../GameCategorySelector/GameCategorySelector";
import GameThemeSelector from "../GameThemeSelector/GameThemeSelector";
import urls from "src/utilities/urls";
import { makeRequest } from "src/utilities/axio.helper";
import { UpdateGameDto } from "../../../models/dto/game.dto";

interface ITableRowComponent {
  game: Game;
  index: number;
  categories: Category[];
  themes: Theme[];
  paginationIndex: any;
  games: Game[];
}

function ReactSwitch(game: Game, checked: boolean, setChecked: any) {
  const saveActive = async (game: Game): Promise<void> => {
    if (game.active === 1) {
      game.active = 0;
      setChecked(false);
      saveGame(game);
    } else {
      game.active = 1;
      setChecked(true);

      saveGame(game);
    }
  };

  return (
    <>
      <Col>
        <Form.Group>
          <Form.Check
            label=""
            type="switch"
            id={game.id.toString()}
            checked={checked}
            onChange={() => saveActive(game)}
          />
        </Form.Group>
      </Col>
    </>
  );
}

async function saveGame(game: Game) {
  const gameThemesIds: number[] = game.gameThemes.map((theme: GameTheme) => {
    return theme.themeId;
  });
  if (game.id) {
    try {
      await makeRequest<Game, UpdateGameDto>("post", `${urls.GAMES_UPDATE}`, {
        id: game.id,
        active: game.active,
        code: game.code,
        name: game.name,
        rating: game.rating,
        gameCategorieIds: game.gameCategoriesIds,
        gameThemesIds: gameThemesIds,
        primaryCategoryId: game.primaryCategory.id,
        providerId: game.provider.id,
        providerWeight: game.providerWeight,
        releasedAt: game.releasedAt,
      });

      toastr.success("Game Updated!");
    } catch (err) {
      toastr.error(err);
    }
  }
}

function getPrimaryGameGategory(props: ITableRowComponent, game: Game) {
  return (
    <GameCategorySelector
      singleSelect
      chosenCategories={game.primaryCategory ? [game.primaryCategory] : []}
      game={game}
      categories={props.categories}
      defaultValue={0}
      onChangeCategories={async (categories: Category[]): Promise<void> => {
        game.primaryCategory = categories[0];
        saveGame(game);
      }}
    />
  );
}

function getRating(game: Game, rating: any, setRating: any) {
  const values: number[] = [
    0, 0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.1, 5.2, 5.3, 5.4, 5.5, 5.6,
    5.7, 5.8, 5.9, 6,
  ];

  return (
    <Form.Control
      as="select"
      custom
      value={rating}
      onChange={(evt: React.ChangeEvent<HTMLInputElement>): void => {
        setRating(evt.target.value);
        game.rating = Number(evt.target.value);
        saveGame(game);
      }}
    >
      {values.map((value) => (
        <option key={value} value={value}>
          {value}
        </option>
      ))}
    </Form.Control>
  );
}

function getAllCategories(props: ITableRowComponent, game: Game) {
  return (
    <GameCategorySelector
      chosenCategories={
        game.gameCategories
          ? game.gameCategories.map(
              (gameCategory: GameCategory): Category => gameCategory.category,
            )
          : []
      }
      game={game}
      categories={props.categories}
      onChangeCategories={async (categories: Category[]): Promise<void> => {
        const newCats = categories.map((category: Category): GameCategory => {
          const gameCategory = new GameCategory();
          gameCategory.categoryId = category.id;
          gameCategory.gameId = game.id;
          gameCategory.category = category;
          return gameCategory;
        });
        game.gameCategories = newCats;

        const gameCategoriesIds: number[] = newCats.map(
          (gameCategory: GameCategory) => {
            return gameCategory.categoryId;
          },
        );
        game.gameCategoriesIds = gameCategoriesIds;

        saveGame(game);
      }}
    />
  );
}
function getAllThemes(props: ITableRowComponent, game: Game) {
  return (
    <GameThemeSelector
      chosenThemes={
        game.gameThemes
          ? game.gameThemes.map(
              (gameTheme: GameTheme): Theme => gameTheme.theme,
            )
          : []
      }
      game={game}
      themes={props.themes}
      onChangeThemes={async (themes: Theme[]): Promise<void> => {
        const newThemes = themes.map((theme: Theme): GameTheme => {
          const gameTheme = new GameTheme();
          gameTheme.themeId = theme.id;
          gameTheme.gameId = theme.id;
          gameTheme.theme = theme;
          return gameTheme;
        });
        game.gameThemes = newThemes;
        saveGame(game);
      }}
    />
  );
}

const TableRow: FunctionComponent<ITableRowComponent> = (
  props: ITableRowComponent,
) => {
  const [checked, setChecked] = useState(
    props.game.active === 1 ? true : false,
  );

  const [rating, setRating] = useState<number>(props.game.rating);

  useEffect(() => {
    setChecked(props.game.active === 1 ? true : false);
  }, [props.games, props.game.active]);

  return (
    <tr key={props.index} className={"text-center"}>
      <td>{props.game.id}</td>
      <td>{props.game.name}</td>
      <td>{props.game.externalId}</td>
      <td>{getAllCategories(props, props.game)}</td>
      <td>{getAllThemes(props, props.game)}</td>
      <td>{getPrimaryGameGategory(props, props.game)}</td>
      <td>{getRating(props.game, rating, setRating)}</td>
      <td>{ReactSwitch(props.game, checked, setChecked)}</td>

      <td>
        <Button
          className="mr-1"
          href={`/games/edit/${props.game.id}`}
          variant="primary"
        >
          Edit
        </Button>
      </td>
    </tr>
  );
};

export default TableRow;

import React, { FunctionComponent } from "react";
import { Table, Card } from "react-bootstrap";
import { GameCategory } from "src/models/app/GameCategory";
import { Game } from "src/models/app/Game";
import { GameCategoryListItem } from "./GameCategoryListItem";

export interface IGameCategoriesProps {
  game: Game;
  refreshData: () => void;
}

export const GameCategoriesList: FunctionComponent<IGameCategoriesProps> = (
  props: IGameCategoriesProps,
) => {
  return (
    <>
      <Card>
        <Card.Header>Game categories</Card.Header>
        <Card.Body>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Game</th>
                <th>Category</th>
                <th>Weight</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {props.game.gameCategories.map(
                (gameCategory: GameCategory, index: number) => (
                  <GameCategoryListItem
                    key={index}
                    game={props.game}
                    gameCategory={gameCategory}
                    onUpdated={props.refreshData}
                  />
                ),
              )}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </>
  );
};

/**
 //////////////////////////////////////////////////////////////////////
 THIS FILE WAS GENEREATED
 Run 'npm run export-models' on backend to sync models
 ((DO NOT MODIFIY!!!))
 //////////////////////////////////////////////////////////////////////
 */

// Models
import { TransactionHistory } from "./TransactionHistory";
import { Transaction } from "./Transaction";

export enum TransactionStates {
  PROCESSED = "PROCESSED",
  PENDING = "PENDING",
  OPEN = "OPEN",
  CANCELED = "CANCELED",
  FAILED = "FAILED",
  REFUNDED = "REFUNDED",
  CORRECTED = "CORRECTED",
}

export function GetTransactionStateWithId(id: number) {
  switch (id) {
    case 1:
      return TransactionStates.PROCESSED;
    case 2:
      return TransactionStates.PENDING;
    case 3:
      return TransactionStates.OPEN;
    case 4:
      return TransactionStates.CANCELED;
    case 5:
      return TransactionStates.FAILED;
    case 6:
      return TransactionStates.REFUNDED;
    case 7:
      return TransactionStates.CORRECTED;
    default:
      break;
  }
}

export enum TransactionStatusForPayments {
  PROCESSED = 1,
  PENDING = 2,
  AUTHORIZED = 3,
  CANCELED = 5,
  FAILED = 6,
  REFUNDED = 7,
}

export class TransactionState {
  id: number;

  name: string;

  description: string;

  createdAt: Date;

  updatedAt: Date;

  deletedAt: Date;

  transactionHistories?: TransactionHistory[];

  transactions?: Transaction[];
}

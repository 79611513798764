import moment from "moment";
import React, { FunctionComponent } from "react";
import { Button } from "react-bootstrap";
import config from "src/config";
import { FreeBet } from "src/models/app/FreeBet";
import { makeRequest } from "src/utilities/axio.helper";
import urls from "src/utilities/urls";

interface ITableRowComponent {
  freeBet: FreeBet;
  index: number;
  refreshData: any;
}

const TableRow: FunctionComponent<ITableRowComponent> = (
  props: ITableRowComponent,
) => {
  const cancelPromo = async (): Promise<void> => {
    try {
      await makeRequest(
        "post",
        urls.NOLIMIT_CANCEL_FREE_SPINS,
        {
          promoName: props.freeBet?.promoName,
        },
        {
          username: config.API_AUTH.USERNAME,
          password: config.API_AUTH.PASSWORD,
        },
      );

      toastr.success(`Promo ${props.freeBet?.promoName} Cancel!`);

      props.refreshData();
    } catch (err) {
      toastr.error(err.message);
    }
  };

  return (
    <tr key={props.index} className={"text-center"}>
      <td>
        <a href={`/accounts/edit/${props.freeBet?.userId}`}>
          {props.freeBet?.userId}
        </a>
      </td>
      <td>{props.freeBet?.operator}</td>
      <td>{props.freeBet?.promoName}</td>
      <td>{props.freeBet?.gameName}</td>
      <td>{props.freeBet?.amount.amount}</td>
      <td>{props.freeBet?.rounds}</td>
      <td>{props.freeBet?.roundsUsed}</td>
      <td> {moment(props.freeBet?.created).format("DD/MM/YYYY hh:mm:ss")}</td>
      <td> {moment(props.freeBet?.startTime).format("DD/MM/YYYY hh:mm:ss")}</td>
      <td> {moment(props.freeBet?.expires).format("DD/MM/YYYY hh:mm:ss")}</td>

      <td>
        <Button className="mr-1" variant="primary" onClick={cancelPromo}>
          Cancel
        </Button>
      </td>
    </tr>
  );
};

export default TableRow;

import React, { FunctionComponent, useState } from "react";
import { Account } from "src/models/app/Account";
import { Table, Button, Card } from "react-bootstrap";
import { DepositLimit } from "src/models/app/DepositLimit";
import { AccountDepositLimitItem } from "./AccountDepositLimitItem";
import { AccountDepositLimitUpdateModal } from "./AccountDepositLimitUpdateModal";

export interface IAccountDepositLimitsProps {
  account: Account;
  refreshAccount: () => void;
}

export const AccountDepositLimits: FunctionComponent<
  IAccountDepositLimitsProps
> = (props: IAccountDepositLimitsProps) => {
  const [showUpgradeModal, setShowUpgradeModal] = useState<boolean>(false);
  const showUpgradeLimitModal = (): void => setShowUpgradeModal(true);
  const hideUpgradeLimitModal = (): void => setShowUpgradeModal(false);

  const getUpdateDepositLimitModal = (): React.ReactChild => {
    if (showUpgradeModal && props.account) {
      return (
        <AccountDepositLimitUpdateModal
          account={props.account}
          removeModal={hideUpgradeLimitModal}
          onUpdated={onUpdatedLimit}
        />
      );
    }

    return <></>;
  };

  const onUpdatedLimit = (): void => {
    props.refreshAccount();
  };

  return (
    <>
      <Card>
        <Card.Header>
          Account Deposit Limits
          <Button
            className="float-right"
            variant="primary"
            onClick={showUpgradeLimitModal}
          >
            Update Limits
          </Button>
        </Card.Header>

        <Card.Body>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Type</th>
                <th>Amount</th>
                <th>Remaining</th>
                <th>Last reset</th>
                <th>Next reset</th>
                <th>Increase</th>
                <th>Next increase</th>
              </tr>
            </thead>

            <tbody>
              {props.account.depositLimits.map((depositLimit: DepositLimit) => (
                <AccountDepositLimitItem
                  key={depositLimit.typeId}
                  depositLimit={depositLimit}
                  account={props.account}
                  onUpdated={onUpdatedLimit}
                />
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>

      {getUpdateDepositLimitModal()}
    </>
  );
};

import DotEnvFlow from "dotenv-flow";

const config = DotEnvFlow.config();

if (!config) {
  // eslint-disable-next-line no-console
  console.error("!!!!!! .env file not found !!!!!!");
}

export const Config = {
  API_URL:
    process.env.REACT_APP_API_URL ||
    "https://api-backoffice-staging.casinoepik.dk",
  ENVIRONMENT: process.env.NODE_ENV,
  SESSION_TOKEN_IDENTIFIER: "session-token",
  // Basic Authentication for admin routes
  API_AUTH: {
    USERNAME: "CEADMIN",
    PASSWORD: "YfZam8ccW79KkijRny9B",
  },
  AUTH: {
    LOGIN_DISABLED: false,
  },
  limits: {
    session: [24, 168, 720, 2160, 8760],
  },
  FRONT_API_URL:
    process.env.REACT_APP_FRONT_URL || "https://staging.casinoepik.dk/",
  BACKEND_API_URL:
    process.env.REACT_APP_BACKEND_URL || "https://api-staging.casinoepik.dk/",
  PORT: process.env.PORT || 5001,
  BACKOFFICE_ADMIN_PASSWORD: "8f46a3bc-46e5-4c93-adb8-3f45a6a120bd",
  GOOGLE_AUTH_CLIENT_ID:
    process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID ||
    "847523358808-tnorbpmi8ki860bk8tgrn8gsvoaqnbfr.apps.googleusercontent.com",
  GCP_STORAGE_BUCKET_URL:
    process.env.GCP_STORAGE_BUCKET_URL || "https://storage.cloud.google.com/",
};

export default Config;

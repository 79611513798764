import React, { FunctionComponent } from "react";
import { ResponseGetCommentDto } from "src/models/dto/comment.dto";
import { Wallet } from "src/models/app/Wallet";

export interface IAccountCommentItemProps {
  commentDto: ResponseGetCommentDto;
}

export const AccountWalletItem: FunctionComponent<Wallet> = (props: Wallet) => {
  return (
    <tr key={props.id}>
      <th>{props.id}</th>
      <th>{props.type.name}</th>
      <th>{props.currency?.code}</th>
      <th>{props.balance}</th>
    </tr>
  );
};

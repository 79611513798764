import React, { FunctionComponent, useEffect, useState } from "react";
import urls from "src/utilities/urls";
import { useHistory } from "react-router-dom";
import { ButtonGroup, Button } from "react-bootstrap";
import Swal, { SweetAlertResult } from "sweetalert2";
import { ThemeUpdateModal } from "../ThemeUpdateModal";
import { Theme } from "src/models/app/Theme";
import { RequestUpdateThemeDto } from "src/models/dto/theme.dto";
import { makeRequest } from "src/utilities/axio.helper";
import { safeFormatDate } from "src/utilities/date";

export interface IThemeItemProps {
  theme: Theme;
  onUpdated: (theme: Theme) => void;
  onDeleted: (theme: Theme) => void;
}

export const ThemeListItem: FunctionComponent<IThemeItemProps> = (
  props: IThemeItemProps,
) => {
  const isThemeDeleted = (theme: Theme): boolean => theme.deletedAt === null;
  const [showUpdateModal, setShowUpdateModal] = useState<boolean>(false);
  const [theme, setTheme] = useState<Theme>(new Theme());
  const history = useHistory();
  const showUpdateThemeModal = (): void => setShowUpdateModal(true);
  const hideUpdateThemeModal = (): void => setShowUpdateModal(false);

  useEffect(() => {
    setTheme(props.theme);
  }, [props.theme]);

  const getUpdateThemeModal = (): React.ReactChild => {
    if (showUpdateModal && props.theme) {
      return (
        <ThemeUpdateModal
          theme={props.theme}
          removeModal={hideUpdateThemeModal}
          onUpdate={props.onUpdated}
        />
      );
    }

    return <></>;
  };

  const toggleTheme = async (active: boolean): Promise<void> => {
    let title = "Are you sure you want to deactivate this theme?";
    if (active) {
      title = "Are you sure you want to activate this theme?";
    }

    const result: SweetAlertResult = await Swal.fire({
      title: title,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes!",
    });

    if (result.isConfirmed) {
      try {
        theme.active = active ? 1 : 0;
        await makeRequest<Theme, RequestUpdateThemeDto>("post", urls.THEMES, {
          id: theme.id,
          name: theme.name,
          code: theme.code,
          active: theme.active,
        });

        if (active) {
          toastr.success("Activated Theme!");
        } else {
          toastr.success("Deactivated Theme!");
        }

        props.onDeleted(props.theme);
      } catch (err) {
        toastr.error(err.message);
      }
    }
  };

  return (
    <>
      <tr key={props.theme.id}>
        <td>{props.theme.id}</td>
        <td>
          {props.theme.name} {!isThemeDeleted(props.theme) ? " (Deleted)" : ""}
        </td>
        <td>{props.theme.code}</td>
        <td>{props.theme.active}</td>
        <td>{safeFormatDate(props.theme.createdAt)}</td>
        <td>{safeFormatDate(props.theme.updatedAt)}</td>
        <td>
          <ButtonGroup className="float-sm-right" aria-label="Basic example">
            {!(props.theme.active === 0) ? (
              <Button variant="danger" onClick={() => toggleTheme(false)}>
                Deactivate
              </Button>
            ) : (
              <Button variant="primary" onClick={() => toggleTheme(true)}>
                Activate
              </Button>
            )}
          </ButtonGroup>
          <ButtonGroup
            className="float-sm-right mr-3"
            aria-label="Basic example"
          >
            <Button variant="info" onClick={showUpdateThemeModal}>
              Update
            </Button>
          </ButtonGroup>
          <ButtonGroup
            className="float-sm-right mr-3"
            aria-label="Basic example"
          >
            <Button
              variant="secondary"
              onClick={() => history.push(`/themes/gamelist/${props.theme.id}`)}
            >
              Games
            </Button>
          </ButtonGroup>
        </td>
      </tr>
      {getUpdateThemeModal()}
    </>
  );
};

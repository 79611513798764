import moment from "moment";
import React, { FunctionComponent } from "react";
import { Table } from "react-bootstrap";
import config from "src/config";
import { AccountDocument } from "src/models/app/AccountDocument";

interface IDocumentTable {
  documents: AccountDocument[];
}
const DocumentTable: FunctionComponent<IDocumentTable> = (
  props: IDocumentTable,
) => {
  return (
    <Table hover className="bg-white">
      <thead>
        <tr className="text-center">
          <th>#</th>
          <th>Account</th>
          <th>File</th>
          <th>State</th>
          <th>Type</th>
          <th>Date of upload</th>
        </tr>
      </thead>
      <tbody>
        {props.documents.map((document, index) => {
          return (
            <tr key={index} className="text-center">
              <td>{document.id}</td>

              <td>
                <a href={`/accounts/edit/${document.accountId}/kyc`}>
                  {document.accountId}
                </a>
              </td>
              <td>
                <a
                  href={`${config.GCP_STORAGE_BUCKET_URL}${document.bucket}/${document.path}`}
                  target={"_blank"}
                  rel="noreferrer noopener"
                >
                  {document.name}
                </a>
              </td>
              <td>{document.state.name}</td>
              <td>{document.type.name}</td>
              <td>
                {moment(document.createdAt).format("DD MM YYYY hh:mm:ss")}
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

export default DocumentTable;
